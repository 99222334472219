import { useState, useRef, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../store/auth-context';

function ResetPassword(props) {
    const queryParams = new URLSearchParams(window.location.search)
    const urlToken = queryParams.get("token")
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    console.log("urlToken", urlToken)
    if(urlToken===null){
        navigate("/auth/login");
    }
    const password = useRef();
    const confirmPassword = useRef();

    const [responsePasswordMsg, setResponsePasswordMsg] = useState("");
    const [responsePasswordChange, setResponsePasswordChange] = useState("");
    const [passwordInputRes, setPasswordInputRes] = useState("");
    const [confirmpasswordRes, setConfirmPasswordRes] = useState("");


    const [isLoading, setIsLoading] = useState(false)

    const matchConfirmPassword = (event) => {
        event.preventDefault()
        let matchPassword = password.current.value
        let matchConfPassword = confirmPassword.current.value
        if (matchConfPassword != "") {
            if (matchPassword === matchConfPassword) {
                setConfirmPasswordRes("matched");
            } else {
                setConfirmPasswordRes("notmatched");
            }
        } else {
            setConfirmPasswordRes(false);
        }
    }
    const correctPassword = (event) => {
        event.preventDefault()
        let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        let matchPassword = password.current.value
        let matchConfPassword = confirmPassword.current.value
        if (matchPassword != "") {
            if (!regularExpression.test(matchPassword)) {
                setPasswordInputRes("notmatched");
            } else {
                setPasswordInputRes(true);
            }
            if (matchConfPassword != "") {
                if (matchPassword === matchConfPassword) {
                    setConfirmPasswordRes("matched");
                } else {
                    setConfirmPasswordRes("notmatched");
                }
            }
        } else {
            setPasswordInputRes(false);
        }
    }


    const submitHandler = (event) => {
        event.preventDefault()
        const enteredPassword = password.current.value
        const enteredConfirmPassword = confirmPassword.current.value
       if(enteredPassword == ""){
        setPasswordInputRes(false);
       }else{
        let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
        if(!regularExpression.test(enteredPassword)) {
            setPasswordInputRes("notmatched");
        }else{
            setPasswordInputRes(true);
        }
       }
       if(enteredConfirmPassword == ""){
        setConfirmPasswordRes(false);
       }else{
        if(enteredPassword===enteredConfirmPassword){
            setConfirmPasswordRes("matched");
        }else{
            setConfirmPasswordRes("notmatched");
        }
       }
       if (enteredPassword != "" && enteredConfirmPassword != "" && confirmpasswordRes === "matched" && passwordInputRes === true) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        // myHeaders.append("Authorization", "Bearer "+authCtx.access_token);

        var raw = JSON.stringify({
        "token": urlToken,
        "new_password": enteredPassword
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        };
        setIsLoading(true)
        let url = 'https://api.nextmetaverse.io/reset-password/';
        fetch(url, requestOptions)
        .then(res => {
            // console.log(res)
            setIsLoading(false)
            if(res.ok) {
              res.json().then(data => {
                //   console.log("data", data)
                setResponsePasswordMsg(data.msg);
                setResponsePasswordChange(true);
                setTimeout(function(){
                    navigate("/auth/login");
                },2000)
              })
              
    
            } else {
              res.json().then(data => {
                // console.log("data", data)
                setResponsePasswordMsg(data.detail);
                setResponsePasswordChange(false);
                
              })
            }
          })
          .catch((err) => {
            console.log(err.message)
          })
        }
      }



    return (

        <div className={`${!props.show ? "opacity-1 visible" : "opacity-0 invisible"} transition-all duration-500 block bg-blue-300 md:bg-black md:bg-opacity-80 h-screen w-full fixed inset-0 flex justify-center items-center z-2000`}>
            <div class="bg-primary p-5 rounded-md h-screen md:h-auto w-full md:max-w-2xl md:relative fixed inset-0 md:top-0 z-2000">
                <div class="px-4 md:py-4">
                    <div class="flex items-center mt-10 md:mt-0">

                        <div class="flex mr-auto items-center space-x-4">

                            <h1 class=""> RESET PASSWORD</h1>

                        </div>
                        {/* <div onClick={props.handleClose2} class="cursor-pointer"><i class="text-3xl fa-solid fa-xmark"></i></div> */}
                    </div>

                    <div className=" ">
                        <label className="">New Password</label>
                        <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="password" ref={password} placeholder="New Password" onKeyUp={correctPassword} />
                    </div>
                    <ul className="text-red-400">
                        {passwordInputRes === false && (
                            <li>Password is required.</li>
                        )}
                        {passwordInputRes === "notmatched" && (
                            <small>Passwords must be at least 8 characters with one uppercase letter, one number, and one special character.</small>
                        )}
                    </ul>
                    <div className=" ">
                        <label className="">Confirm New password</label>
                        <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="confirmpassword" ref={confirmPassword} placeholder="Confirm New Password" onKeyUp={matchConfirmPassword} />
                    </div>
                    <small>Passwords must be at least 8 characters with one uppercase letter, one number, and one special character.</small>
                    <ul className="text-red-400">
                        {confirmpasswordRes === false && (
                            <li>Confirm password is required.</li>
                        )}
                        {confirmpasswordRes === "notmatched" && (
                            <li>Password is not Matched.</li>
                        )}
                    </ul>
                    {confirmpasswordRes === "matched" && (
                        <ul className="text-green-200">
                            <li>Password is Matched.</li>
                        </ul>
                    )}
                    <button class="hover:opacity-75  w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onClick={submitHandler}>
                        <strong>RESET</strong>
                    </button>
                    {responsePasswordChange === false && (
                    <ul className="text-red-400">
                        <li>{responsePasswordMsg}</li>
                    </ul>
                    )}
                    {responsePasswordChange === true && (
                    <ul className="text-green-200">
                        <li>{responsePasswordMsg}</li>
                    </ul>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ResetPassword