import account from '../assets/img/icons/icon--account.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import PhotoModal from '../components/PhotoModal';
import ChangePass from '../components/ChangePass';
import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useContext, useEffect } from 'react';
import AuthContext from '../store/auth-context';
import ShimmerEffect from '../components/ShimmerComponent';
function Account(props) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const image5 = require('../assets/img/maps/map--small-5.jpg')
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(true);
  const [modal2, setModal2] = useState(true);
  const [isChecked1, setIsChecked1] = useState(false);

  const handleOnChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const [isChecked2, setIsChecked2] = useState(false);

  const handleOnChange2 = () => {
    setIsChecked2(!isChecked2);
  };
  const [isChecked3, setIsChecked3] = useState(false);

  const handleOnChange3 = () => {
    setIsChecked3(!isChecked3);
  };
  const [isChecked4, setIsChecked4] = useState(false);

  const handleOnChange4 = () => {
    setIsChecked4(!isChecked4);
  };
  function handleGameClick() {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    } else {
      setDisabled(!disabled);
    }


  }
  function handleModal() {
    setModal(!modal);

  }
  function handleModal2() {

    setModal2(!modal2);

  }
  function toggleCollapse() {
    const appInfo = document.getElementById('app-info');
    appInfo.classList.toggle('info--collapsed');
  }
  function openNav() {
    var bodyClass = document.querySelector('body')
    bodyClass.classList.toggle('closed')
  }
  function closeNav() {
    var bodyClass = document.querySelector('body')
    bodyClass.classList.remove('closed')
  }
  function notSoldYet() {

    if (window.innerWidth < 768) {
      const appInfo = document.getElementById('app-info');
      appInfo.classList.toggle('info--collapsed');
    }
    var notSoldYet = document.querySelector('.not-sold-wrap')
    notSoldYet.classList.add('flex')
    notSoldYet.classList.remove('hidden')
  }
  function notSoldYetClose() {
    var notSoldYet = document.querySelector('.not-sold-wrap')
    if (window.innerWidth < 768) {
      const appInfo = document.getElementById('app-info');
      appInfo.classList.toggle('info--collapsed');
    }
    notSoldYet.classList.remove('flex')
    notSoldYet.classList.add('hidden')
  }
  const size = useWindowSize()
  let button = ''
  if (modal) {
    button = ''
  } else {
    button = <PhotoModal />;

  }

  // get Profile 

  const inputUserName = useRef();
  const inputEmail = useRef();
  const inputFirst_Name = useRef();
  const inputLast_Name = useRef();
  const inputFacebook = useRef();
  const inputTwitter = useRef();
  const inputDiscord = useRef();
  const inputWallet = useRef();
  const inputDescription = useRef();

  const [userNameRes, setuserNameRes] = useState("");
  const [emailRes, setEmailRes] = useState("");
  const [first_NameRes, setFirst_NameRes] = useState("");
  const [last_NameRes, setLast_NameRes] = useState("");
  const [userName, setuserName] = useState("");
  const [email, setEmail] = useState("");

  const [first_Name, setFirst_Name] = useState("");
  const [last_Name, setLast_Name] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [wallet, setWallet] = useState("");
  const [description, setDescription] = useState("");

  const [oneTimeRes, setOneTimeRes] = useState(0);
  const [responseAjaxMessage, setResponseAjaxMessage] = useState("");
  const [responseAjax, setResponseAjax] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  // console.log(authCtx.access_token)
  const loadProfileData = () => {
    if (oneTimeRes === 0) {
      // setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + authCtx.access_token);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://api.nextmetaverse.io/users/me", requestOptions)
        .then(res => {
          // console.log(res)
          if (res.ok) {
            res.json().then(data => {
              console.log("data", data)
              setuserName(data.username);
              setEmail(data.email);
              setFirst_Name(data.first_name);
              setLast_Name(data.last_name);
              setFacebook(data.facebook);
              setTwitter(data.twitter);
              setDiscord(data.discord);
              setWallet(data.wallet);
              setDescription(data.description);
              // setResponseAjax(true);
              // setResponseAjaxMessage(data);
            })
          } else {
            res.json().then(data => {
              // console.log("data", data)
              // setResponseAjax(false);
              // setResponseAjaxMessage(data);
            })
          }
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err.message)
        })
      setOneTimeRes(1);
    }
  }

  useEffect(() => {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    } else {
      loadProfileData();
    }
  },[]);


  const saveChange = (event) => {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    } else {
      event.preventDefault()
      const enteredFirst_Name = inputFirst_Name.current.value
      const enteredLast_Name = inputLast_Name.current.value
      const enteredUserName = inputUserName.current.value
      const enteredEmail = inputEmail.current.value
      const enteredFacebook = inputFacebook.current.value
      const enteredTwitter = inputTwitter.current.value
      const enteredDiscord = inputDiscord.current.value
      // const enteredWallet = inputWallet.current.value
      const enteredDescription = inputDescription.current.value
      if (enteredEmail == "") {
        setEmailRes(false);
      } else {
        setEmailRes(true);
      }
      if (enteredFirst_Name == "") {
        setFirst_NameRes(false);
      } else {
        setFirst_NameRes(true);
      }
      if (enteredLast_Name == "") {
        setLast_NameRes(false);
      } else {
        setLast_NameRes(true);
      }
      if (enteredUserName == "") {
        setuserNameRes(false);
      } else {
        setuserNameRes(true);
      }
      if (enteredEmail != "" && enteredFirst_Name != "" && enteredLast_Name != "" && enteredUserName != "") {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + authCtx.access_token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "email": enteredEmail,
          "username": enteredUserName,
          "first_name": enteredFirst_Name,
          "last_name": enteredLast_Name,
          "facebook": enteredFacebook,
          "twitter": enteredTwitter,
          "discord": enteredDiscord,
          "wallet": "",
          "description": enteredDescription
        });

        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("https://api.nextmetaverse.io/users/me", requestOptions)
          .then(res => {
            // console.log(res)
            setIsLoading(false)
            if (res.ok) {
              res.json().then(data => {
                // console.log("data", data)
                setResponseAjax(true);
              })
            } else {
              res.json().then(data => {
                // console.log("data", data)
                setResponseAjax(false);
              })
            }
          })
          .catch(error => console.log('error', error));
        setDisabled(!disabled)
      } else {
        setDisabled(disabled)
      }
    }
  }

  const updateState = (event) => {
    // event.preventDefault();
    let id = event.target.id;
    if (id == "first_name") {
      let newenteredFirst_Name = inputFirst_Name.current.value
      setFirst_Name(newenteredFirst_Name);
    } else if (id == "last_name") {
      let newenteredLast_Name = inputLast_Name.current.value
      setLast_Name(newenteredLast_Name);
    } else if (id == "discord") {
      let newenteredDiscord = inputDiscord.current.value
      setDiscord(newenteredDiscord);
    } else if (id == "facebook") {
      let newenteredfacebook = inputFacebook.current.value
      setFacebook(newenteredfacebook);
    } else if (id == "twitter") {
      let newenteredtwitter = inputTwitter.current.value
      setTwitter(newenteredtwitter);
    } else if (id == "description") {
      let newentereddescription = inputDescription.current.value
      setDescription(newentereddescription);
    }
  }




  return (
    <div className="h-screen">

      <div className="body max-w-full h-full flex flex-col ">

        <TopNavBar icon={account} page="account" />
        <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
          <LeftNavBar page="account" />
          {isLoading ? <ShimmerEffect /> : (
            <>
              <HumMenu />

              <main className="main w-full px-3  ">
                <div className="">
                  <div className="max-w-7xl  mx-auto ">
                    <div className="md:shadow-inset-card md:bg-opacity-50 bg-blue-300 md:p-10 rounded-md mt-4 md:px-8 w-12/12 md:ml-12 mb-4">
                      <div className="flex md:flex-row flex-col  md:items-center mb-4">
                        <p className="md:text-3xl text-2xl  font-bold mr-auto">ACCOUNT INFORMATION</p>
                        {responseAjax === false && (
                          <ul className="text-red-400">
                            <li>Changes not Saved!</li>
                          </ul>
                        )}
                        {responseAjax === true && (
                          <ul className="text-green-200">
                            <li>Changes Saved Successfully☺</li>
                          </ul>
                        )}
                        <span>
                          {!disabled ? (<button className="hover:opacity-75  w-64 uppercase text-center bg-gradient-light  sm:p-3 p-2 rounded-lg" onClick={saveChange}>
                            <strong>SAVE CHANGES</strong>
                          </button>) :
                            (<button className="hover:opacity-75  w-64 uppercase text-center bg-gradient-light  sm:p-3 p-2 rounded-lg" onClick={handleGameClick}>
                              <strong>EDIT PROFILE</strong>
                            </button>
                            )}
                        </span>
                      </div>
                      <div className="flex flex-col lg:flex-row lg:-mx-4">
                        <div className="w-full  lg:w-1/3 lg:px-4 h-full">
                          <div className="  shadow-inset-card bg-primary rounded-md p-10 mb-4 w-full h-full flex justify-center items-center">
                            <div className="flex flex-col items-center">
                              <img src={image5} className="rounded-full border-2 border-white w-56 h-56"></img>
                              <button className="hover:opacity-75  w-64 uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onClick={handleModal}>
                                <strong>UPLOAD/CHANGE IMAGE</strong>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="lg:w-2/3 w-full lg:px-4">
                          <div className="flex flex-col md:flex-row md:mb-4 md:-mx-2">
                            <div className="mb-4 md:mb-0 md:px-2 w-full md:w-1/2">
                              <label className="font-bold text-xs">FIRST NAME</label>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white" : "bg-primary text-white"}`} type="text" name="firstname"
                                disabled={disabled} onChange={updateState} value={first_Name} id='first_name' placeholder="First name" ref={inputFirst_Name} />
                              {first_NameRes === false && (
                                <small className="text-red-400">First Name is required.</small>
                              )}
                            </div>


                            <div className="mb-4 relative md:mb-0 md:px-2 flex flex-col justify-end w-full md:w-1/2">
                              <div className="flex justify-between">
                                <label className="font-bold text-xs">EMAIL</label>
                                <div className="flex text-xs opacity-70">
                                  <span className={`${!isChecked1 ? "flex" : "hidden"}`}>NOT</span><span className="ml-1 "> PUBLIC</span>
                                </div>
                              </div>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white  bg-opacity-50" : "bg-primary text-white"}`} type="email" name="email"
                                value={email} placeholder="Email" disabled={disabled} ref={inputEmail} />
                              {emailRes === false && (
                                <small className="text-red-400">
                                  Email is required.
                                </small>
                              )}
                              <div className={`absolute right-2 md:top-4 top-2.5 switch ${!disabled ? "block" : "hidden"}`}>
                                <input type="checkbox" onChange={handleOnChange1} id="emailSwitch" />
                                <label for="emailSwitch"></label>
                              </div>

                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row md:mb-4 md:-mx-2">
                            <div className="mb-4 md:mb-0 md:px-2 w-full md:w-1/2">
                              <label className="font-bold text-xs">LAST NAME</label>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white" : "bg-primary text-white"}`} type="text" name="lastname"
                                value={last_Name} placeholder="Last Name" onChange={updateState} disabled={disabled} id='last_name' ref={inputLast_Name} />
                              {last_NameRes === false && (
                                <small className="text-red-400">
                                  Last Name is required.
                                </small>
                              )}
                            </div>

                            <div className="mb-4 md:mb-0 md:px-2 w-full md:w-1/2">
                              <label className="font-bold text-xs">USERNAME</label>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white" : "bg-primary text-white"}`} type="text" name="username"
                                value={userName} placeholder="Username" disabled={disabled} ref={inputUserName} />
                              {userNameRes === false && (
                                <small className="text-red-400">
                                  Username is required.
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row md:mb-4 md:-mx-2">
                            <div className="mb-4 md:mb-0 md:px-2 w-full md:w-1/2">
                              <label className="font-bold text-xs">POLYGON ADDRESS</label>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white  bg-opacity-50" : "bg-primary text-white"}`} type="text" name="polygonaddress"
                                value="" placeholder="Polygon Address" id='polygon' disabled={disabled} />
                            </div>
                            <div className="mb-4 relative md:mb-0 md:px-2 flex flex-col justify-end w-full md:w-1/2">
                              <div className="flex justify-between">
                                <label className="font-bold text-xs">DISCORD NAME</label>
                                <div className="flex text-xs opacity-70">
                                  <span className={`${!isChecked2 ? "flex" : "hidden"}`}>NOT</span><span className="ml-1 "> PUBLIC</span>
                                </div>
                              </div>
                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white  bg-opacity-50" : "bg-primary text-white"}`} type="text" name="discordname"
                                value={discord} placeholder="Discord name" id='discord' disabled={disabled} onChange={updateState} ref={inputDiscord} />
                              <div className={`absolute right-2 md:top-4 top-2.5 switch ${!disabled ? "block" : "hidden"}`}>
                                <input type="checkbox" onChange={handleOnChange2} id="discordSwitch" />
                                <label for="discordSwitch"></label>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row md:mb-4 md:-mx-2">
                            <div className="mb-4 relative md:mb-0 md:px-2 flex flex-col justify-end w-full md:w-1/2">
                              <div className="flex justify-between">
                                <label className="font-bold text-xs">FACEBOOK</label>
                                <div className="flex text-xs opacity-70">
                                  <span className={`${!isChecked3 ? "flex" : "hidden"}`}>NOT</span><span className="ml-1 "> PUBLIC</span>
                                </div>
                              </div>

                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white" : "bg-primary text-white"}`} type="text" name="facebook"
                                value={facebook} placeholder="@username" id='facebook' disabled={disabled} onChange={updateState} ref={inputFacebook} />
                              <div className={`absolute right-2 md:top-2.5 top-2.5 switch ${!disabled ? "block" : "hidden"}`}>
                                <input type="checkbox" onChange={handleOnChange3} id="facebookSwitch" />
                                <label for="facebookSwitch"></label>
                              </div>
                            </div>

                            <div className="mb-4 relative md:mb-0 md:px-2 flex flex-col justify-end w-full md:w-1/2">
                              <div className="flex justify-between">
                                <label className="font-bold text-xs">TWITTER</label>
                                <div className="flex text-xs opacity-70">
                                  <span className={`${!isChecked4 ? "flex" : "hidden"}`}>NOT</span><span className="ml-1 "> PUBLIC</span>
                                </div>
                              </div>

                              <input className={`w-full mt-1  px-6 py-3 rounded-lg text-black ${!disabled ? "bg-white" : "bg-primary text-white"}`} type="text" name="twitter"
                                value={twitter} placeholder="@username" id='twitter' disabled={disabled} onChange={updateState} ref={inputTwitter} />
                              <div className={`absolute right-2 md:top-2.5 top-2.5 switch ${!disabled ? "block" : "hidden"}`}>
                                <input type="checkbox" onChange={handleOnChange4} id="twitterSwitch" />
                                <label for="twitterSwitch"></label>
                              </div>
                            </div>
                          </div>
                          <div className="my-8">

                            <label className="font-bold text-xl ">
                              <i className="mr-5 fas fa-lock"></i>
                              <span>Password</span></label>


                            <div onClick={handleModal2} className={`${!disabled ? "bg-white text-black" : "bg-primary text-white pointer-events-none"} w-full  mt-2 text-2xl  px-6 py-3 rounded-lg cursor-pointer`}>
                              <div className="flex items-center">
                                <div className="mr-auto">
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                  <i className="fa-solid fa-star"></i>
                                </div>
                                <div className={`${!disabled ? "bg-white text-black" : "bg-primary text-white"}   text-lg`}>CHANGE PASSWORD</div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-8">

                            <label className="font-bold text-xl ">Introduction</label>


                            <textarea className={`w-full mt-2  px-6 py-3 rounded-lg text-black  ${!disabled ? "bg-white " : "bg-primary text-white"}`} name="description"
                              rows="7" placeholder="Introduction" id='description' value={description} disabled={disabled} onChange={updateState} ref={inputDescription}>{description}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </>
          )}


        </div>

      </div>
      <PhotoModal show={modal} handleClose={handleModal} />
      <ChangePass show={modal2} handleClose2={handleModal2} />
    </div>
  )
}
// Hook
// const appInfo = document.getElementById('app-info');

function useWindowSize() {

  // const appInfo = document.getElementById('app-info');
  // const appInfo = document.getElementById('app-info');
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });


  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  if (document.getElementById('btn-collapse-app-info') != null) {
    const appInfo = document.getElementById('app-info')
    // console.log(appInfo)
    if (window.innerWidth < 768) {
      appInfo.classList.add('info--collapsed');
    } else if (window.innerWidth > 767) {
      appInfo.classList.remove('info--collapsed');
    }
  }
  return windowSize;
}


// const appInfo = document.getElementById('app-info');
// function checkScreenSize() {
//   if (window.innerWidth < 768) {
//     appInfo.classList.add('info--collapsed');
//   } else if(window.innerWidth > 767){
//     appInfo.classList.remove('info--collapsed');
//   }
// }

// checkScreenSize();

export default Account