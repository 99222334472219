// import classes from './Layout.module.css'
import briefcase from '../../assets/img/icons/icon--briefcase.svg'
import { useState, useEffect } from "react";
import account from '../../assets/img/icons/icon--account.svg'
import arrows from '../../assets/img/icons/icon--arrows.svg'
import briefcaseAccent from '../../assets/img/icons/icon--briefcase-accent.svg'
import buildingAccent from '../../assets/img/icons/icon--building-accent.svg'
import layers from '../../assets/img/icons/icon--layers.svg'
import warning from '../../assets/img/icons/icon--warning.svg'
import mapMarkerAccent from '../../assets/img/icons/icon--map-marker-accent.svg'
import location from '../../assets/img/icons/icon--location.svg'
import map from '../../assets/img/icons/icon--map.svg'
import building from '../../assets/img/icons/icon--building.svg'
import store from '../../assets/img/icons/icon--store.svg'
import history from '../../assets/img/icons/icon--history.svg'
import historyDark from '../../assets/img/icons/icon--history-dark.svg'
import logout from '../../assets/img/icons/icon--logout.svg'
import upload from '../../assets/img/icons/icon--upload.svg'
import notification from '../../assets/img/icons/icon--notification.svg'
import collapse from '../../assets/img/icons/icon--collapse.svg'
import LeftNavBar from './LeftNavBar'
import TopNavBar from './TopNavBar'
import { Link } from 'react-router-dom'
import AuthContext from "../../store/auth-context";
import { useContext } from 'react';

function HumMenu() {
  const authCtx = useContext(AuthContext)
  // console.log(authCtx)
  const isLoggedIn = authCtx.isLoggedIn
    function openNav() {
        var bodyClass =  document.querySelector('body')
        bodyClass.classList.toggle('closed') 
      }
      function closeNav() {
        var bodyClass =  document.querySelector('body')
        bodyClass.classList.remove('closed') 
      }
      const logoutHandler = () => {
        authCtx.logout()
      }
    return (
        <aside id="app-menu-primary" class="fixed inset-0 flex z-40 w-full ">
        <div onClick={closeNav} aria-hidden="true" class="fixed inset-0 bg-gray-600 bg-opacity-75 nav-button gray-overlay"></div>
        <div class="app-menu-primary-inner relative flex-1 flex flex-col max-w-xs w-full bg-primary min-h-full">
          <div class="absolute top-0 right-0 -mr-12 pt-2"><button onClick={openNav} type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white nav-button"><span class="sr-only">Close sidebar</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="h-6 w-6 text-white"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg></button></div>
          <div class="flex-1 flex flex-col px-3 py-8 overflow-y-auto">
            <nav class="">
              <ul class="flex flex-col space-y-2">
                <button onClick={openNav} class="flex items-center group  text-ne-deepPurple nav-button"><div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div><svg class="w-6 h-6 flex-shrink-0 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 19H5L5.007 12H7L7 17H12V19ZM18.992 12H17V7L12 7V5L19 5L18.992 12Z"></path>
                  </svg>
                  </div></div><span class="ml-4 font-bold text-xs uppercase">Toggle menu</span></button>
                <li class="h-8">
                  <Link onClick={closeNav} to="/" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 bg-ne-deepPurple"><div>
                      <img  class="h-6" src={map} alt="Marker on map"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">MAP</span>
                  </Link>
                </li>
                <li class="h-8">
                <Link onClick={closeNav} to="/buildings" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={building} alt="Buildings"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">BUILDINGS</span>

                  </Link>
                </li>
                <li class="h-8">
                <Link onClick={closeNav} to="/marketplace" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={store} alt="Store"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">MARKETPLACE</span>
                  </Link>
                </li>
                <li class="h-8">
                <Link onClick={closeNav} to="/leaderboard" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <svg class="w-6 h-6 flex-shrink-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.5 21H2L2 9H7.5L7.5 21ZM14.75 3L9.25 3L9.25 21H14.75L14.75 3ZM22 11L16.5 11V21L22 21L22 11Z" fill="white"></path>
                        </svg>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">LEADERBOARD</span>
                  </Link>
                </li>
                <li class="h-8">
                  <a href="#" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={briefcase} alt="Briefcase"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">JOBS</span>
                  </a>
                </li>
                <li class="h-8">
                  <Link onClick={closeNav} to="/history" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={history} alt="History"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">HISTORY</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div class="flex-shrink-0 flex flex-col px-3 py-4 space-y-2">
            <nav class="">
              <ul class="flex flex-col space-y-2">
                <li class="h-8">
                  <a href="#" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={notification} alt="Notifications"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">NOTIFICATIONS</span>
                  </a>
                </li>
                {isLoggedIn && <li class="h-8">
                  <Link onClick={closeNav} to="/account" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={account} alt="Account"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">PROFILE</span>
                  </Link>
                </li>}
                <li class="h-8">
                  <a href="01-map-page-v1.html" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={upload} alt="Upload"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">HOMEPAGE</span>
                  </a>
                </li>
                {isLoggedIn && <li onClick={logoutHandler} class="h-8">
                  <Link onClick={closeNav} to="/auth/login" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <img class="h-6" src={logout} alt="Logout"/>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">LOGOUT</span>
                  </Link>
                </li>}
                {!isLoggedIn && <li class="h-8">
                  <Link onClick={closeNav} to="/auth/login" class="flex items-center group cursor-pointer text-white">
                    <div class="group-hover:bg-ne-deepPurple group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                      <div>in</div>
                      </div></div>
                      <span class="ml-4 font-bold text-xs uppercase">LOG IN</span>
                  </Link>
                </li>}
              </ul>
            </nav>
          </div>
          
        </div>
        <div class="flex-shrink-0 w-14"></div>
      </aside>
    )
}

export default HumMenu