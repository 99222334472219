import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import map from '../assets/img/icons/icon--map.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import BuyNowMain from '../components/BuyNowMain'
import MakeOfferMain from '../components/MakeOfferMain'
import HistoryMain from "../components/HistoryMain";
import Example from '../components/Cesium'
import BuildingInformation from "../components/BuildingInformation";
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
import Web3Modal from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider'
import NM from "../abis/NextMetaverse.json";
import {contract_address} from "../config"
import Web3 from "web3";
import SelectWalletModal from "../components/SelectWalletModal";
import { useWallet, UseWalletProvider } from 'use-wallet'
import axios from "axios";
function MapPage(props) {
  const web3=new Web3()
  const wallet = useWallet()
  const [weiPrice,setWeiPrice]=useState("")
//   const infuraId = "97551c0b16fe42e286c1114e62df50de";
//   const providerOptions = {
//     walletconnect: {
//         package: WalletConnectProvider, // required
//         options: {
//             infuraId // required
//         },
//     },
// }

// const web3Modal = new Web3Modal(
//   {
//       providerOptions,
//       cacheProvider: false,
//   }
// );
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext)
  // console.log(authCtx)
  const isLoggedIn = authCtx.isLoggedIn
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [maticPrice, setmaticPrice] = useState("-");
  const [usdtPrice, setusdtPrice] = useState("-");
  const [loader, setloader] = useState(false);
  const [historyBTN, sethistoryBTN] = useState(true);
  const [makeofferBTN, setmakeofferBTN] = useState(true);
  const [buynowBTN, setbuynowBTN] = useState(true);
  const [trigger, setTrigger] = useState(false);


  const TriggerHandlerTrue = ()=>{
     setTrigger(true)
  }
  const TriggerHandlerFalse= ()=>{
     setTrigger(false)
  }


  const selectedBuildingHandler = (building) => {
    
    
    // console.log("building",building)
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    let usdt_price = 0;
    usdt_price = Cookies.get('usdt_price');
     
    
    fetch("https://api.nextmetaverse.io/buildings/"+building.BIN, requestOptions)
      .then(response => response.json())
      .then((result) =>{ 
        // console.log("result111111",result)
        if(result.type == 1){
          sethistoryBTN(true)
          setmakeofferBTN(true)
          setbuynowBTN(false)
        }
        else if(result.type == 2){
          sethistoryBTN(false)
          setmakeofferBTN(false)
          setbuynowBTN(true)
        }
        else{
          sethistoryBTN(false)
          setmakeofferBTN(false)
          setbuynowBTN(false)
        }
        setmaticPrice((result.price_matic).toFixed(2));
        setusdtPrice((Number(usdt_price) * Number(result.price_matic)).toFixed(2))
        setloader(false)
        // Fetch Matic Current USDT
      })
      .catch(error => console.log('error', error));



      setloader(true)
      setSelectedBuilding(building);
      setTrigger(false)

    
  };

  const getUsdPrice=async()=>{
    const {data}=await axios.get(`https://api.coinbase.com/v2/exchange-rates?currency=MATIC`)
  return data?.data?.rates["USD"];

  }
  const clearBuildingHandler = () => {
    setSelectedBuilding(null);
  };
  const [historyOnOff, setHistoryOnOff] = useState(false);
  const [buyNowOnOff, setBuyNowOnOff] = useState(false);
  const [walletOnOff, setWalletOnOff] = useState(false);
  const [makeOfferOnOff, setMakeOfferOnOff] = useState(false);
  const historyOnHandler = () => {
   
    setHistoryOnOff(historyOnOff => true)
    setBuyNowOnOff(buyNowOnOff => false)
    setMakeOfferOnOff(makeOfferOnOff => false)
  };
  const historyOffHandler = () => {
   
    setHistoryOnOff(historyOnOff => false)
  };
  const buyNow =async()=>{
    // onOpen()
    // console.log("buy now clicked");
    // await web3Modal.connect()
    // .then(async (provider) => {
    //   console.log("abi: ", NM.abi)
    //   const web3 = new Web3(provider);
    //   let accounts = await web3.eth.getAccounts();
    //   const contract = new web3.eth.Contract(NM.abi, contract_address);
    //   await contract.methods
    //   .buyNow("0x52a14051469844A1C5D30f975BF041482BF04EB3", accounts[0], 3)
    //   .send({ from: accounts[0] })
    // })
    // .catch(e=>console.log(e))
  }
  const buyNowOnHandler = () => {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    }
    
     setBuyNowOnOff(buyNowOnOff => true)
     setHistoryOnOff(historyOnOff => false)
     setMakeOfferOnOff(makeOfferOnOff => false)
    }
   
   const buyNowOffHandler = () => {
    
     setBuyNowOnOff(buyNowOnOff => false)
   };

   const walletOnHandler = () => {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    }
    setWalletOnOff(buyNowOnOff => true)
  };

   const walletOffHandler = () => {
    
    setWalletOnOff(walletOnOff => false)
  };

   const makeOfferOnHandler = () => {
    if (authCtx.access_token == null) {
      navigate("/auth/login");
    }

    setMakeOfferOnOff(makeOfferOnOff => true)
    setHistoryOnOff(historyOnOff => false)
    setBuyNowOnOff(buyNowOnOff => false)
  };
  const makeOfferOffHandler = () => {
   
    setMakeOfferOnOff(makeOfferOnOff => false)
  };
    function toggleCollapse() {
        const appInfo = document.getElementById('app-info');
        appInfo.classList.toggle('info--collapsed');
      } 
      const size = useWindowSize()
    return (
      <>
      <div className="h-full flex flex-col">
        
    <TopNavBar wallet={wallet} walletOnHandler={walletOnHandler} icon={map} page="map"/>
    
        
      <div className="flex flex-auto items-stretch md:pt-0 pt-10">
      <LeftNavBar page="map"/>
      <HumMenu/>
     <BuildingInformation toggleCollapse={toggleCollapse} historyOnHandler={historyOnHandler} makeOfferOnHandler={makeOfferOnHandler} buyNowOnHandler={buyNowOnHandler}
      selectedBuilding={selectedBuilding}
      clearBuildingHandler={clearBuildingHandler} 
      maticPrice = {maticPrice}
      usdtPrice = {usdtPrice}
      loader={loader}
      historyBTN={historyBTN}
      makeofferBTN={makeofferBTN}
      buynowBTN={buynowBTN}
      trigger={trigger}
      TriggerHandlerTrue={TriggerHandlerTrue}
      />
      
       <BuyNowMain weiPrice={weiPrice} selectedBuilding={selectedBuilding} wallet={wallet}  walletOnHandler={walletOnHandler} buyNow={buyNow} buyNowOnOff={buyNowOnOff} buyNowOffHandler={buyNowOffHandler} />
       <SelectWalletModal wallet={wallet} walletOnOff={walletOnOff} walletOffHandler={walletOffHandler} />

      <HistoryMain historyOnOff={historyOnOff} historyOffHandler={historyOffHandler} />
      <MakeOfferMain makeOfferOnOff={makeOfferOnOff} makeOfferOffHandler={makeOfferOffHandler} /> 
    </div>

    
  
  <div className="absolute inset-0 ">
  <Example  setSelectedBuilding={selectedBuildingHandler} 
      selectedBuilding={selectedBuilding}
      TriggerHandlerFalse={TriggerHandlerFalse}
      />
      
     
      </div>
      

</div>
<SelectWalletModal  /></>
    )
  }
// Hook
// const appInfo = document.getElementById('app-info');

function useWindowSize() {
  
    // const appInfo = document.getElementById('app-info');
    // const appInfo = document.getElementById('app-info');
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      // Add event listener
      window.addEventListener("resize", handleResize);
  
      // Call handler right away so state gets updated with initial window size
      handleResize();
  
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
   
    if (document.getElementById('btn-collapse-app-info') != null) {
      const appInfo = document.getElementById('app-info')
      // console.log(appInfo)
    if (window.innerWidth < 768) {
      appInfo.classList.add('info--collapsed');
    }
    //  else if(window.innerWidth > 767){
    //   appInfo.classList.remove('info--collapsed');
    // }
    }
    return windowSize;
  }
  // Person.defaultProps = {
  //   name: "Rahul",
  //   eyeColor: "deepblue",
  //   age: "45"
  // }

 
  
  // const appInfo = document.getElementById('app-info');
  // function checkScreenSize() {
  //   if (window.innerWidth < 768) {
  //     appInfo.classList.add('info--collapsed');
  //   } else if(window.innerWidth > 767){
  //     appInfo.classList.remove('info--collapsed');
  //   }
  // }
  
  // checkScreenSize();

export default MapPage