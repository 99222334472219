import logout from '../assets/img/icons/icon--logout.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';
function NotificationPage(props) {
  const authCtx = useContext(AuthContext)
  useEffect(() => {
     
     if(Cookies.get('access_token') === undefined) {
       authCtx.logout();
   }
     },[])
    return (
        <div class="h-screen">
    <div class="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={logout} page="register"/>
    <div class="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="login"/>
      <HumMenu/>

      
      
    </div>

    
  </div>
  </div>
    )
}


export default NotificationPage