import { useState, useEffect, useContext } from "react";
import Card from '../components/Card'
import account from '../assets/img/icons/icon--account.svg'
import arrows from '../assets/img/icons/icon--arrows.svg'
import buildingAccent from '../assets/img/icons/icon--building-accent.svg'
import layers from '../assets/img/icons/icon--layers.svg'
import warning from '../assets/img/icons/icon--warning.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import value from '../assets/img/icons/icon--value.svg'
import axios from "axios";
import cart from '../assets/img/icons/icon--cart.svg'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';

function ProfilePage(props) {
  const authCtx = useContext(AuthContext)
  useEffect(() => {
     
     if(Cookies.get('access_token') === undefined) {
       authCtx.logout();
   }
     },[])
    const image5 = require('../assets/img/maps/map--small-5.jpg')
    const [table, setTable] = useState(false);

    const inTable = () => {
     
      setTable(table => true)
    };
    const inCard = () => {
      setTable(table => false)
    };
    const [filter, setFilter] = useState(false);

    const toggleFilter = () => {
      setFilter(!filter);
    };
    
      
     
    return (
        <div className="h-screen">
         
    <div className="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={account} page="profile"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="profile"/>
      <HumMenu/>
<main className="w-full h-full px-4 md:ml-12 mt-8">
<div className="max-w-7xl  mx-auto ">
  <div className="bg-blue-300 p-8 md:shadow-inset-card">
    <h1 className="text-center md:text-left mt-4 md:mb-4">USER INFORMATION</h1>
    <div className="md:flex md:space-x-10">
    <img src={image5} className="mx-auto  mt-6 md:mt-0  rounded-full  w-56 h-56"></img>
    <div className="flex-1">
    <p className="text-xs mb-1 font-bold">USERNAME</p>
    <div className="bg-primary w-full py-4 px-4 rounded-md">Omar</div>
    </div>
    </div>
    </div>
    <div className="md:flex">
    <div className="flex flex-row md:flex-col md:flex-1 mt-10 mb-0.5">
        <div className="flex-1 justify-start md:text-center bg-primary py-5 text-lg font-bold mr-0.5 md:mb-0.5  shadow-inset-card px-2">NET WORTH</div>
        <div className="flex-1 justify-end text-center bg-primary py-5 text-lg px-2 mr-0.5">182 USDT</div>
    </div>
    <div className="flex flex-row md:flex-col md:flex-1 md:mt-10 mb-0.5">
        <div className="flex-1 justify-start md:text-center bg-primary py-5 text-lg font-bold mr-0.5 md:mb-0.5  shadow-inset-card px-2">NET WORTH</div>
        <div className="flex-1 justify-end text-center bg-primary py-5 text-lg px-2 mr-0.5">182 USDT</div>
    </div>
    <div className="flex flex-row md:flex-col md:flex-1 md:mb-0.5 md:mt-10">
        <div className="flex-1 justify-start md:text-center bg-primary py-5 text-lg font-bold mr-0.5 md:mb-0.5  shadow-inset-card px-2">NET WORTH</div>
        <div className="flex-1 justify-end text-center bg-primary py-5 text-lg px-2 mr-0.5">182 USDT</div>
    </div>
    </div>
    <div className="mt-20  flex items-center"><div className="mr-auto text-4xl font-bold">OWNED LANDS</div>
    <div className="text-lg flex items-center space-x-3">
    <div className={`h-8 w-8 rounded-full flex justify-center items-center cursor-pointer ${!table ? "bg-gradient-light" : ""}`}><i onClick={inCard} className="fa-solid fa-table-cells-large"></i></div>
      <div className={`h-8 w-8 rounded-full flex justify-center items-center cursor-pointer ${table ? "bg-gradient-light" : ""}`}><i onClick={inTable} className="fa-solid fa-table-list"></i></div>
      </div>
      </div>
    <div className="flex flex-wrap items-center md:-mx-2">
    <div className="mb-4 relative lg:mb-0 md:px-2 flex items-center justify-center  w-full md:w-1/2 lg:w-1/3">
         
            <input className={`w-full mt-1 lg:mt-0 pl-4 pr-12 py-3 rounded-lg text-black `} type="text" name="polygonaddress"
                        defaultValue=""    placeholder="@username"/> 
                        <div className=""></div>

                        <div className="absolute text-black right-4 md:right-6 top-2.5"><i className="fa-brands fa-searchengin text-2xl"></i></div>
        </div>
        <div className="mb-4 relative lg:mb-0 md:px-2 flex flex-col justify-end w-full md:w-1/2 lg:w-1/3">
         
            <select className={`w-full mt-1 lg:mt-0 pl-4 pr-12 py-3 rounded-lg text-black styled-select`} type="text" name="polygonaddress"
                        defaultValue=""    placeholder="@username">
                            <option value="volvo">Volvo</option>
                            <option value="saab">Saab</option>
                            <option value="mercedes">Mercedes</option>
                            <option value="audi">Audi</option>
                            </select> 
                        <div className=""></div>

                        <div className="absolute text-black right-4 md:right-6 top-2.5"><i className="fa-solid fa-chevron-down text-xl"></i></div>
        </div>
        <div className="mb-4 relative lg:mb-0  md:px-2 flex flex-col justify-end w-full md:w-1/2 lg:w-1/3">
         <div className="relative">
            <div onClick={toggleFilter} className={`cursor-pointer w-full flex text-black pl-4 pr-4 md:pr-4 py-2 border-b-2 rounded-t-lg bg-white flex items-center ${filter ? "border-b-2 border-black " : "rounded-b-lg"}`}>
              <div className="mr-auto">Filter</div><div><i className="fa-brands fa-searchengin text-2xl"></i></div></div>
              <ul className={`absolute right-0 left-0 top-13 ${filter ? "block" : "hidden"}`}>
              <li className={`w-full  px-4 py-2  text-black bg-white flex items-center`}>
              <div className="mr-auto">Filter</div><input
                                    id="residential"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="residential"
                                 /></li>
              <li className={`w-full  px-4 py-2  text-black bg-white flex items-center`}>
              <div className="mr-auto">Filter</div><input
                                    id="residential"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="residential"
                                 /></li>
              <li className={`w-full  px-4 py-2 rounded-b-lg text-black bg-white flex items-center ${filter ? "rounded-b-lg" : ""}`}>
              <div className="mr-auto">Filter</div><input
                                    id="residential"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="residential"
                                 /></li>
              </ul>
              </div>
                       
        </div>
        </div>
        <div className={`mx-auto grid grid-cols-1 content-center gap-7 sm:grid-cols-2 md:gird-cols-3 lg:grid-cols-4 md:mt-8 ${!table ? "gird" : "hidden"}`}>
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        </div>
        <div className={`mt-10 rounded-xl overflow-x-auto bg-blue-300 bg-opacity-75 p-6 shadow-inset-card ${table ? "block" : "hidden"}`}>
        <table className="table-spacing">
  <thead>
    <tr>
      <th className="p-2.5 min-w-68" ></th>
      <th className="p-2.5">NAME</th>
      <th className="p-2.5">TILE COUNT</th>
      <th className="p-2.5">PURCHASED FOR</th>
      <th className="p-2.5">PRICE PER TILE</th>
      <th className="p-2.5">VALUE CHANGE</th>
      <th className="p-2.5" colSpan="2">ACTIONS</th>
    </tr>
  </thead>
  <tbody className="">
    <tr className="bg-primary">
   
    <th className="w-18 h-18 bg-cover bg-center bg-image" ></th>
      <th className="text-lg p-2.5">11 Place Adolphe Chérioux, 75015 Paris, France</th>
      <th className="p-2.5">
      <div className="flex space-x-4 items-center">
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                20
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                </div>
      </th>
      <th className="p-2.5">34.23 USDT</th>
      <th className="p-2.5">0.7 USDT</th>
      <th className="p-2.5">43.43 USDT</th>
      <th className="p-2.5"><i className="fa-solid fa-hotel"></i></th>
    </tr>
    <tr className="bg-primary">
   
   <th className="w-18 h-18 bg-cover bg-center bg-image" ></th>
     <th className="text-lg p-3">11 Place Adolphe Chérioux, 75015 Paris, France</th>
     <th className="p-2.5">
     <div className="flex space-x-4 items-center">
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                20
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                </div>
     </th>
     <th className="p-2.5">34.23 USDT</th>
     <th className="p-2.5">0.7 USDT</th>
     <th className="p-2.5">43.43 USDT</th>
     <th className="p-2.5"><i className="fa-solid fa-hotel"></i></th>
   </tr>
    
  </tbody>
</table>
</div>

        </div>
</main>
      
      
    
</div>
    
  </div>
 
  </div>
    )
}


export default ProfilePage