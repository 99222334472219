import  NextMetaverse from "../abis/NextMetaverse.json"
import {contract_address} from "../config"
import Web3 from "web3"
import React from 'react';
import { useForm } from "react-hook-form";
import Web3Modal from "web3modal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletConnectProvider from "@walletconnect/web3-provider";


function BuyNowMain(props) {
  const { register, handleSubmit, reset, formState } = useForm();
    const { errors } = formState;
  const wallet=props?.wallet
  // console.log(wallet)
  const web3=new Web3(wallet.ethereum)
  
  const buildingId=props?.selectedBuilding? props?.selectedBuilding['BIN']:null
const building=props?.selectedBuilding
  async function handleBuyNow()
  {

   
  
  if (wallet?.chainId=="80001" ||wallet?.chainId==80001  )
  {
    
    const nm = new web3.eth.Contract(NextMetaverse.abi,contract_address );
    await nm.methods.mint(wallet?.account,buildingId)
    .send({ from: wallet.account, value:props?.weiPrice  })
    .then(r=>console.log("Nft has been minted Successfully"))
    .catch((e)=>{
     console.log(e)
      toast(e.message)
    })
  }
  else
  {
    
    toast("please switch to matic ")
  }
  }
  
    
    return (<>
    <ToastContainer />
<main className={`${props.buyNowOnOff ? "flex" : "hidden"} relative z-50 flex-auto h-auto bg-glass pt-16 pb-6 not-sold-wrap`}>
        
        <div class="w-11/12 max-w-6xl mx-auto buyNowPopUp flex flex-col items-center relative justify-end xl:px-20 md:px-16 px-8 md:py-12 py-6 mt-1 bg-primary not-sold">
        <div onClick={props.buyNowOffHandler}  class="text-gradient-light absolute top-0 right-0 py-2 px-5 text-3xl cursor-pointer">&#10005;</div>
        <form onSubmit={handleSubmit(handleBuyNow)}>
          <div class="w-full relative flex xl:flex-row flex-col xl:justify-between pt-5 border-t border-gray-200 flex-wrap">
    <h2 class="">Total:</h2>
    <h1 class="xl:order-1 order-1  xl:mb-0 mb-4 ">{building==undefined?"-":Number(building['price']).toFixed(4)+" MATIC"}</h1>
            <label class="xl:w-full flex items-center xl:order-2 order-2" for="tos">
              <input id="tos" class="min-w-5 h-5 mr-3 flex self-center rounded appearance-none" type="checkbox" name="tos" {...register("acceptTerms", { required: true })}/>
              <p><strong>I have read and accept terms and conditions</strong></p>
             
            </label>
            
          </div>
          {errors.acceptTerms?.type === 'required' && <div className=" text-red-400 ">You must accept the terms and conditions</div>}
          
          {props?.wallet.status=="connected"?
          // onClick={handleBuyNow}
          <button   data-modal-toggle="walletModal"  class="hover:opacity-75 max-w-sm sm:w-4/5 w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>Buy now</strong>
          </button>
          :
          <button type="button" onClick={props?.walletOnHandler} data-modal-toggle="walletModal"  class="hover:opacity-75 max-w-sm sm:w-4/5 w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong >Connect Wallet</strong>
          </button>
}
</form>

        </div>
        
      </main>
     

  
      </>
    )
}

export default BuyNowMain