import logout from '../assets/img/icons/icon--logout.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useContext } from 'react';
import AuthContext from '../store/auth-context';
import axios from 'axios'
import { useForm } from "react-hook-form";
function Login(props) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const emailInputRef = useRef()
    const passwordInputRef = useRef()
    const [isLogin, setIsLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, seterrorMessage] = useState('')
    const switchAuthModeHandler = () => {
      setIsLogin((prevState) => !prevState);
    };
    const authCtx = useContext(AuthContext)
    console.log(authCtx)
    const password = useRef({});
    const email = useRef({});
    const submitHandler = (event) => {
      seterrorMessage('')
      let enteredEmail = watch("email", "");
      let enteredPassword = watch("password", "");
      // event.preventDefault()
      // const enteredEmail = emailInputRef.current.value
      // const enteredPassword = passwordInputRef.current.value
      
      let urlencoded = new URLSearchParams();
  urlencoded.append("username", enteredEmail);
  urlencoded.append("password", enteredPassword);
      setIsLoading(true)
      let url
      if(isLogin) {
        
        url = 'https://api.nextmetaverse.io/login/access-token'
      } else {
      url = 'https://api.nextmetaverse.io/login/access-token'
        
      }
      // axios.post(url, urlencoded).then(res => {
      //     // console.log(res)
      //     console.log('res.data.access_token',res.data.access_token)
      //     // setIsLoading(false)
      //     if(res.status == 400){
      //       alert(1)
      //     }
      //     if(res.status = 200) {
            
      //        const expirationTime = new Date(new Date().getTime() + 300000)
      //           // authCtx.login(res.data.access_token)
      //        authCtx.login(res.data.access_token, expirationTime.toISOString())
      //        navigate("/");
            
            
  
      //     } else {
      //       res.json().then(data => {
      //         //show error
      //         let errorMessage = 'Authentication failed!'
      //         // if(data && data.error && data.error.message) {
      //         //  errorMessage = data.error.message
      //         // }
             
      //         throw new Error(errorMessage)
              
      //       })
      //     }
      //   }).then((data) => {
         
         
      //   })
      //   .catch((err) => {
      //     throw new Error(err)
      //   })


      fetch(url,
        {
          method: 'POST',
          body: urlencoded,
          redirect: 'follow',
          header: {
            'Content-Type' : 'application/x-www-form-urlencoded'
          }
        }).then(res => {
          // console.log('res',res)
          setIsLoading(false)
          if(res.ok) {
            res.json().then(data => {
                const expirationTime = new Date(new Date().getTime() + 300000)
             authCtx.login(data.access_token, expirationTime.toISOString())
            //  setResponseAjax(true);
             navigate("/");
            })
            
  
          } else {
            res.json().then(data => {
               seterrorMessage(data.detail)
            })
          }
        })
        .catch((err) => {
          // console.log(err.message)
        })



    }
  
    return (
        <div className="h-screen">
    <div className="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={logout} page="login"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="login"/>
      <HumMenu/>

     <main className=" w-full">
         <div className="flex h-full items-center px-5 ">
           <div className="mx-auto">
               <div className="max-w-lg">
         <p className="text-center text-3xl loginSubtitle mb-1">Welcome to Next Metaverse project!</p>
        <p className="text-center loginTitle mb-6">LOGIN</p>
        
        <form onSubmit={handleSubmit(submitHandler)}>
        <div className="mt-6 relative ">
        
        <label className="">Email address</label>
        <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="text" ref={emailInputRef} name="email"
                           placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })} />
        {errors.email?.type === 'required' && <div className="text-red-400 absolute left-0">You must specify an email</div>}
        {errors.email?.type === 'pattern' && <div className="text-red-400 absolute left-0">Not a valid email</div>}
    </div>
        
    <div className="mt-6 relative">
            <label className="">Password</label>
            <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" ref={passwordInputRef} name="password"
                               placeholder="Password" {...register("password", {
                                required: "You must specify a password",
                                
                              })} />
                              <div className="absolute left-0">
       {errors.password && <p className="text-red-400 ">{errors.password.message}</p>}
        </div>
        </div>

        
        <button className="mt-8 hover:opacity-75  w-full uppercase text-center bg-gradient-light  sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>LOG IN</strong>
        </button>
        </form>
        {errorMessage && errorMessage !== null ? (<><p style={{color: "#ff5252",fontSize: '15px'}}>{errorMessage}</p></>) : (null)}
        <div className="flex mt-3">
            <Link to="/auth/forgot-password" className="mr-auto  text-s border-white border-b-2">Forgot password?</Link>
            <Link to="/auth/register" className="border-white border-b-2 text-s">Register</Link>
        </div>
        </div>
        </div>
        </div>
     </main>
      
    </div>

    
  </div>
  </div>
    )
}

export default Login