

import React, { useEffect } from "react";

import * as Cesium from "cesium";
// import { Cartesian3 } from "cesium";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import MapPage from './pages/MapPage'
import BuildingsPage from './pages/BuildingsPage'
import Leaderboard from './pages/Leaderbord'
import Marketplace from './pages/Marketplace'
import HistoryPage from './pages/History'
import BuildingIdPage from './pages/BuidlingId'
import Login from './pages/Login'
import Register from './pages/Register'
import ForgotMyPassword from './pages/ForgotMyPassword'
import Account from './pages/Account'
import ProfilePage from './pages/ProfilePage'
import NotificationPage from "./pages/NotificationPage";
import AuthenticationExample from "./pages/AuthenticationExample";
import { useContext } from "react";
import Cookies from 'js-cookie';
import ResetPassword from "./pages/ResetPassword";

import briefcase from '../src/assets/img/icons/icon--briefcase.svg'

import account from '../src/assets/img/icons/icon--account.svg'
import arrows from '../src/assets/img/icons/icon--arrows.svg'
import briefcaseAccent from '../src/assets/img/icons/icon--briefcase-accent.svg'
import buildingAccent from '../src/assets/img/icons/icon--building-accent.svg'
import layers from '../src/assets/img/icons/icon--layers.svg'
import warning from '../src/assets/img/icons/icon--warning.svg'
import mapMarkerAccent from '../src/assets/img/icons/icon--map-marker-accent.svg'
import location from '../src/assets/img/icons/icon--location.svg'
import map from '../src/assets/img/icons/icon--map.svg'
import building from '../src/assets/img/icons/icon--building.svg'
import store from '../src/assets/img/icons/icon--store.svg'
import history from '../src/assets/img/icons/icon--history.svg'
import historyDark from '../src/assets/img/icons/icon--history-dark.svg'
import logout from '../src/assets/img/icons/icon--logout.svg'
import upload from '../src/assets/img/icons/icon--upload.svg'
import notification from '../src/assets/img/icons/icon--notification.svg'
import collapse from '../src/assets/img/icons/icon--collapse.svg'
import { useWallet, UseWalletProvider } from 'use-wallet'
import WalletConnectProvider from '@walletconnect/web3-provider';


// const wallet = new WalletConnectProvider({
// 	rpc: {
// 		80001: 'https://rpc-mumbai.matic.today',
// 	},
// });
// import TopNavBar from './components/TopNavBar'
// import LeftNavBar from './components/LeftNavBar'



import './App.css';
import AuthContext from "./store/auth-context";
// window.CESIUM_BASE_URL = '/';
const wallet = new WalletConnectProvider({
	rpc: {
		80001: 'https://rpc-mumbai.maticvigil.com/',
	},
});

function App() {
  const authCtx = useContext(AuthContext)
  useEffect(() => {
  //   var inFifteenMinutes = new Date(new Date().getTime() + 0.2 * 60 * 1000);
  //       Cookies.set('access_token', Cookies.get('access_token'), {expires: inFifteenMinutes })
  //   const expirationTime = new Date(new Date().getTime() + 15 * 60 * 1000);
  //   // authCtx.login(res.data.access_token)
  // // authCtx.login(Cookies.get('access_token'), expirationTime.toISOString())
  // // console.log(authCtx.login)
 
  // console.log(Cookies.get('access_token'))
  if(Cookies.get('access_token') === 'null') {
    authCtx.logout();
}
  },[])

  // window.CESIUM_BASE_URL = '/src/assets/';
//  console.log(authCtx.isLoggedIn)
  return (
    <UseWalletProvider
			chainId={80001}
			connectors={{
			walletconnect:wallet
			}}
		>
    <div class="h-full">
      
    
   
    <BrowserRouter>
     
        <Routes>
          <Route path="/" element={<MapPage />} />
          <Route path="/buildings" element={<BuildingsPage />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/history" element={<HistoryPage />} />
          <Route path="/building/:id" element={<BuildingIdPage />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="/auth/forgot-password" element={<ForgotMyPassword />} />
          {/* {
            authCtx.isLoggedIn && (
          <Route path="/account" element={<Account />} />
          )
          }  */}
          {authCtx.isLoggedIn ? (
         <Route path="/account" element={<Account />} />
          ) : (
            <Route
            path="*"
            element={<Navigate to="/auth/login" />}
        />
          )}
          
          {/* <Route path="/account" element={<Account />} /> */}
          <Route path="/profile" element={<ProfilePage />} />
            
          <Route path="/notification" element={<NotificationPage />} />
          <Route path="/a" element={<AuthenticationExample />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
        path="*"
        element={<Navigate to="/" />}
    />
          
        </Routes>
        
    </BrowserRouter>
    </div>
    </UseWalletProvider>
  );
}


export default App;
