import {useState, useEffect, useContext} from "react";
import arrows from '../assets/img/icons/icon--arrows.svg'
import briefcaseAccent from '../assets/img/icons/icon--briefcase-accent.svg'
import buildingAccent from '../assets/img/icons/icon--building-accent.svg'
import layers from '../assets/img/icons/icon--layers.svg'
import warning from '../assets/img/icons/icon--warning.svg'
import store from '../assets/img/icons/icon--store.svg'
import collapse from '../assets/img/icons/icon--collapse.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import Card from '../components/Card'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
// MarketPlace icons
import value from '../assets/img/icons/icon--value.svg'
import search from '../assets/img/icons/icon--search.svg'
import industrial from '../assets/img/icons/icon--industrial.svg'
import cart from '../assets/img/icons/icon--cart.svg'

// import value from '../assets/img/icons/icon--value.svg'

function Marketplace(props) {
   const authCtx = useContext(AuthContext)
   useEffect(() => {
      
      if(Cookies.get('access_token') === undefined) {
        authCtx.logout();
    }
      },[])
   let image5 = require('../assets/img/maps/map--small-5.jpg')
   image5 = image5.default
console.log(image5)
   function marketplaceToggle() {

      var bodyClass = document.querySelector('body')
      const appInfo = document.getElementById('app-info');
      appInfo.classList.toggle('info--collapsed');

      if (appInfo.classList.contains('info--collapsed') && window.innerWidth < 768) {
         // do some stuff

         bodyClass.classList.remove('overflow-imp')
         appInfo.classList.remove('fixed-content');
      } else if (!appInfo.classList.contains('info--collapsed') && window.innerWidth < 768) {
         appInfo.classList.add('fixed-content');

         bodyClass.classList.add('overflow-imp')
      }
   }

   const size = useWindowSize()

   return (
      <div className="h-full">
         <div className="body max-w-full h-full flex flex-col">
            <TopNavBar icon={store} page="marketplace"/>
            <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
               <LeftNavBar page="marketplace"/>
               <HumMenu/>
               <aside id="app-info" className="w-96 self-stretch min-h-full marketplace-app-info pt-8 bg-blue-300">
                  <header
                     className="px-5 relative flex sm:flex-row flex-col sm:items-center sm:justify-between uppercase">
                     <div className="max-w-sm flex sm:flex-row flex-col sm:items-center items-start">
                        <h5 className="flex mr-4 sm:mb-0 mb-2 info-title">
                           <img className="h-6 mr-2" src={store} alt=""/>
                           MARKETPLACE
                        </h5>
                     </div>
                     <button onClick={marketplaceToggle} id="btn-collapse-app-info"
                             className="w-6 h-6 absolute cursor-pointer">
                        <img src={collapse} alt="Toggle app info"/>
                     </button>
                  </header>
                  <div className="rounded-lg sm:p-6 p-5 info-content mt-6">
                     <div className="relative mb-5">
                        <input className="w-full bg-light pr-14 pl-6 py-3 rounded-lg" type="text" name="search"
                               placeholder="Search username"/>
                        <img className="absolute top-3 right-6 h-6" src={search} alt=""/>
                     </div>
                     <details className="bg-dark-glass mb-4" open>
                        <summary className="px-7 py-4 flex items-center">
                           <h5 className="uppercase mr-auto">Building Type</h5>
                           <div className="arrow-down"></div>
                        </summary>
                        <form className="px-7 pb-8">
                           <fieldset className="mb-3">
                              <label className="flex items-center justify-between" for="residential">
                                 <div className="flex items-center">
                                    <img className="mr-2" src={buildingAccent} alt=""/>
                                    <p><small><strong>Residential</strong></small></p>
                                 </div>
                                 <input
                                    id="residential"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="residential"
                                 />
                              </label>
                           </fieldset>
                           <fieldset className="mb-3">
                              <label className="flex items-center justify-between" for="business">
                                 <div className="flex items-center">
                                    <img className="mr-2" src={briefcaseAccent} alt=""/>
                                    <p><small><strong>Business</strong></small></p>
                                 </div>
                                 <input
                                    id="business"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="business"
                                 />
                              </label>
                           </fieldset>
                           <fieldset className="mb-3">
                              <label className="flex items-center justify-between" for="industrial">
                                 <div className="flex items-center">
                                    <img className="mr-2" src={industrial} alt=""/>
                                    <p><small><strong>Industrial</strong></small></p>
                                 </div>
                                 <input
                                    id="industrial"
                                    className="w-5 h-5 rounded appearance-none"
                                    type="checkbox"
                                    name="industrial"
                                 />
                              </label>
                           </fieldset>
                        </form>
                     </details>
                     <details className="bg-dark-glass mb-4" open>
                        <summary className="px-7 py-4 flex items-center">
                        <div className="mr-auto flex">
                           <img className="w-5 h-5 mr-2" src={layers} alt=""/>
                           <h5 className="uppercase">Floors Count</h5>
                           </div>
                           <div className="arrow-down"></div>
                        </summary>
                        <form className="px-7 pb-8">
                           <fieldset className="flex items-center justify-between ">
                              <input
                                 id="floors-count-min"
                                 className="w-2/5 px-3.5 py-2 rounded text-dark font-bold bg-light"
                                 type="text"
                                 name="floors-count-min"
                                 value="Min 01"
                              />
                              <p><strong>to</strong></p>
                              <input
                                 id="floors-count-max"
                                 className="w-2/5 px-3.5 py-2 rounded text-dark font-bold bg-light"
                                 type="text"
                                 name="floors-count-max"
                                 value="Max 1000"
                              />
                           </fieldset>
                        </form>
                     </details>
                     <details className="bg-dark-glass mb-4" open>
                        <summary className="px-7 py-4 flex items-center">
                           <div className="mr-auto flex">
                           <img className="w-5 h-5 mr-2" src={value} alt=""/>
                           <h5 className="uppercase">Price (matic)</h5>
                           </div>
                           <div className="arrow-down"></div>
                        </summary>
                        <form className="px-7 pb-8">
                           <fieldset className="flex items-center justify-between ">
                              <input
                                 id="floors-count-min"
                                 className="w-2/5 px-3.5 py-2 rounded text-dark font-bold bg-light"
                                 type="text"
                                 name="floors-count-min"
                                 value="Min 01"
                              />
                              <p><strong>to</strong></p>
                              <input
                                 id="floors-count-max"
                                 className="w-2/5 px-3.5 py-2 rounded text-dark font-bold bg-light"
                                 type="text"
                                 name="floors-count-max"
                                 value="Max 1000"
                              />
                           </fieldset>
                        </form>
                     </details>
                     <details className="bg-dark-glass mb-4" open>
                        <summary className="px-7 py-4 flex items-center">
                           <h5 className="uppercase mr-auto">Boroughs</h5>
                           <div className="arrow-down"></div>
                        </summary>
                        <form className="px-7 pb-4">
                           <fieldset className="mb-3">
                              <label className="flex items-center justify-between" for="land-art">
                                 <p><small><strong>Manhattan</strong></small></p>
                                 <input id="industrial" className="w-5 h-5 rounded appearance-none"
                                        type="checkbox" name="industrial"/>
                              </label>
                           </fieldset>
                        </form>
                     </details>
                     <button className="w-full uppercase text-center bg-gradient-light mb-5 sm:p-3 p-2 rounded-lg">
                        <strong>Apply</strong>
                     </button>
                  </div>
               </aside>
               <main className="flex-auto h-fit min-h-full pt-8 marketplace-buildings">
                   <div className="w-full flex flex-wrap justify-end mx-auto  max-w-7xl mb-10 px-6">
          <div className="max-w-xs w-full bg-blue-300 px-6 py-4 rounded-lg ">
            <select id="testSelect" className="w-full font-light bg-blue-300  rounded-lg">
              <option value="1" className="block w-full" selected>Listing date from newest</option> 
              <option value="2" className="block w-full">Listing date from newest</option> 
              <option value="2" className="block w-full">Listing date from newest</option> 
            </select>
          </div>
        </div>
                  <div className="container-marketplace w-full max-w-7xl px-6 mx-auto grid grid-cols-1 content-center gap-4 sm:grid-cols-2 md:gird-cols-3 lg:grid-cols-4 ">
                     
                  <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
        <Card image5={image5} buildingAccent={buildingAccent}
        layers={layers} arrows={arrows} warning={warning} cart={cart}
        value={value}
        />
                  </div>
               </main>
            </div>
         </div>
      </div>
   )
   // Hook
   // const appInfo = document.getElementById('app-info');

   function useWindowSize() {

      // const appInfo = document.getElementById('app-info');
      // const appInfo = document.getElementById('app-info');
      // Initialize state with undefined width/height so server and client renders match
      // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
      const [windowSize, setWindowSize] = useState({
         width: undefined,
         height: undefined,
      });

      useEffect(() => {
         // Handler to call on window resize
         function handleResize() {
            // Set window width/height to state
            setWindowSize({
               width: window.innerWidth,
               height: window.innerHeight,
            });
         }

         // Add event listener
         window.addEventListener("resize", handleResize);

         // Call handler right away so state gets updated with initial window size
         handleResize();

         // Remove event listener on cleanup
         return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount

      if ((document.querySelector('.marketplace-app-info') != null)) {
         const appInfoMarket = document.querySelector('.marketplace-app-info');

         if (window.innerWidth < 768) {
            // const appInfo = document.getElementById('app-info');
            // appInfo.classList.add('fixed-content');
            const appInfo = document.getElementById('app-info');
            if (!appInfo.classList.contains('info--collapsed')) {
               // do some stuff
               const appInfo = document.getElementById('app-info');
               appInfo.classList.add('fixed-content');
               var bodyClass = document.querySelector('body')
               bodyClass.classList.add('overflow-imp')
            }
            // appInfoMarket.classList.add('info--collapsed');
            // var bodyClass =  document.querySelector('body')
            //     bodyClass.classList.add('overflow-imp')
            //     bodyClass.classList.add('h-screen');
            //     appInfoMarket.classList.add('overflow-scroll');

         } else if (window.innerWidth > 769) {
            const appInfo = document.getElementById('app-info');
            // var bodyClass =  document.querySelector('body')
            //     bodyClass.classList.remove('overflow-imp')
            //     bodyClass.classList.remove('h-screen');
            //     appInfoMarket.classList.remove('overflow-scroll');
            appInfoMarket.classList.remove('info--collapsed');


            // do some stuff
            // const appInfo = document.getElementById('app-info');
            appInfo.classList.remove('fixed-content');
            var bodyClass = document.querySelector('body')
            bodyClass.classList.remove('overflow-imp')

         }
      }
      return windowSize;
   }
}


export default Marketplace