import React, { useState } from "react";
import Cookies from 'js-cookie'
const AuthContext = React.createContext({
    access_token: '',
    userId: '',
    isLoggedIn: false,
    login: (access_token) => {

    },
    logout: () => {

    }
})

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime()
    const adjExpirationTime = new Date(expirationTime).getTime()

    const remainingDuration = adjExpirationTime - currentTime

    return remainingDuration
}

export const AuthContextProvider = (props) => {
    const initialToken = Cookies.get('access_token')
    const [access_token, setToken] = useState(initialToken)

    const userIsLoggedIn = !!access_token
    
    const logoutHandler = () => {
        setToken(null)
        Cookies.remove('access_token')
        Cookies.remove('usdt_price')
    }
    const loginHandler = (access_token, expirationTime) => {

        setToken(access_token)
        var inFifteenMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
        Cookies.set('access_token', access_token, {expires: inFifteenMinutes })
        
        // const remainingTime = calculateRemainingTime(expirationTime)
        if(Cookies.get('access_token') === undefined) {
            logoutHandler();
        }
        // console.log(Cookies.get('access_token'))
        // setTimeout(logoutHandler, remainingTime)
        
    }
    const contextValue = {
        access_token: access_token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }
    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext