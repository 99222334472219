export const Color1 = {
    color_0_to_5: "#fff7ec",
    color_5_to_10: "#fde5c2",
    color_10_to_25: "#fdcc96",
    color_25_to_50: "#fca771",
    color_50_to_100: "#f4764f",
    color_100_to_200: "#dd3f2a",
    color_200_to_300: "#b80604",
    color_above_300: "#7f0000",
};
export const Color2 = {
    color_0_to_5: "#fff7fb",
    color_5_to_10: "#e8e3f0",
    color_10_to_25: "#c4cbe2",
    color_25_to_50: "#90b4d5",
    color_50_to_100: "#509ac6",
    color_100_to_200: "#1279b4",
    color_200_to_300: "#045d92",
    color_above_300: "#023858",
};
export const Color3 = {
    color_0_to_5: "#f7fcfd",
    color_5_to_10: "#dbe8f2",
    color_10_to_25: "#b5cce2",
    color_25_to_50: "#96abd1",
    color_50_to_100: "#8c7dba",
    color_100_to_200: "#894da2",
    color_200_to_300: "#821680",
    color_above_300: "#4d004b",
};
export const Color4 = {
    color_0_to_5: "#fff5eb",
    color_5_to_10: "#fde2c7",
    color_10_to_25: "#fdc692",
    color_25_to_50: "#fd9f56",
    color_50_to_100: "#f67824",
    color_100_to_200: "#df5106",
    color_200_to_300: "#ad3802",
    color_above_300: "#7f2704",
};
export const Color5 = {
    color_0_to_5: "#f7fcfd",
    color_5_to_10: "#e1f3f6",
    color_10_to_25: "#bde6dd",
    color_25_to_50: "#83ceb9",
    color_50_to_100: "#50b689",
    color_100_to_200: "#2b9553",
    color_200_to_300: "#05712f",
    color_above_300: "#00441b",
};
export const Color6 = {
    color_0_to_5: "#ffffe5",
    color_5_to_10: "#fef4b5",
    color_10_to_25: "#feda7e",
    color_25_to_50: "#feb13e",
    color_50_to_100: "#f3811d",
    color_100_to_200: "#d55607",
    color_200_to_300: "#a03703",
    color_above_300: "#662506",
};
export const Color7 = {
    color_0_to_5: "#ffffe5",
    color_5_to_10: "#f2fab5",
    color_10_to_25: "#ccea9d",
    color_25_to_50: "#96d385",
    color_50_to_100: "#58b669",
    color_100_to_200: "#2b8f4a",
    color_200_to_300: "#056c38",
    color_above_300: "#004529",
};
export const Color8 = {
    color_0_to_5: "#fff5f0",
    color_5_to_10: "#fddacb",
    color_10_to_25: "#fcaf93",
    color_25_to_50: "#fb8060",
    color_50_to_100: "#f44f38",
    color_100_to_200: "#d52221",
    color_200_to_300: "#aa1016",
    color_above_300: "#67000d",
};
export const Color9 = {
    color_0_to_5: "#fff7f3",
    color_5_to_10: "#fcdcd8",
    color_10_to_25: "#fbbabc",
    color_25_to_50: "#f887ac",
    color_50_to_100: "#e84a9b",
    color_100_to_200: "#bb0f85",
    color_200_to_300: "#810178",
    color_above_300: "#49006a",
};
export const Color10 = {
    color_0_to_5: "#f7fcf5",
    color_5_to_10: "#e0f3db",
    color_10_to_25: "#bce4b5",
    color_25_to_50: "#8dd08b",
    color_50_to_100: "#56b567",
    color_100_to_200: "#2b944b",
    color_200_to_300: "#05712f",
    color_above_300: "#00441b",
};
export const Color11 = {
    color_0_to_5: "#ffffd9",
    color_5_to_10: "#e7f5b1",
    color_10_to_25: "#b2e1b6",
    color_25_to_50: "#64c3be",
    color_50_to_100: "#2ca0c1",
    color_100_to_200: "#206cae",
    color_200_to_300: "#243a96",
    color_above_300: "#081d58",
};
export const Color12 = {
    color_0_to_5: "#fcfbfd",
    color_5_to_10: "#eceaf3",
    color_10_to_25: "#d1d1e6",
    color_25_to_50: "#afaed3",
    color_50_to_100: "#8c89c0",
    color_100_to_200: "#705da9",
    color_200_to_300: "#572d91",
    color_above_300: "#3f007d",
};
export const Color13 = {
    color_0_to_5: "#f7fcf0",
    color_5_to_10: "#ddf1d7",
    color_10_to_25: "#c1e7c0",
    color_25_to_50: "#94d5bb",
    color_50_to_100: "#61bdcc",
    color_100_to_200: "#3597c4",
    color_200_to_300: "#0d6dae",
    color_above_300: "#084081",
};
export const Color14 = {
    color_0_to_5: "#ffffff",
    color_5_to_10: "#ececec",
    color_10_to_25: "#d1d1d1",
    color_25_to_50: "#acacac",
    color_50_to_100: "#828282",
    color_100_to_200: "#5b5b5b",
    color_200_to_300: "#2b2b2b",
    color_above_300: "#000000",
};
export const Color15 = {
    color_0_to_5: "#ffffcc",
    color_5_to_10: "#feea9a",
    color_10_to_25: "#fecd6a",
    color_25_to_50: "#fda245",
    color_50_to_100: "#fc6931",
    color_100_to_200: "#ea2820",
    color_200_to_300: "#c20324",
    color_above_300: "#800026",
};
export const Color16 = {
    color_0_to_5: "#f7f4f9",
    color_5_to_10: "#e4dbec",
    color_10_to_25: "#d0aed4",
    color_25_to_50: "#d27fbd",
    color_50_to_100: "#e3429a",
    color_100_to_200: "#d51864",
    color_200_to_300: "#9f0245",
    color_above_300: "#67001f",
};
export const Color17 = {
    color_0_to_5: "#f7fbff",
    color_5_to_10: "#dae8f5",
    color_10_to_25: "#bad6eb",
    color_25_to_50: "#88bedc",
    color_50_to_100: "#539ecc",
    color_100_to_200: "#2a7ab9",
    color_200_to_300: "#0b559f",
    color_above_300: "#08306b",
};
export const Color18 = {
    color_0_to_5: "#fff7fb",
    color_5_to_10: "#e8dfee",
    color_10_to_25: "#c4cbe2",
    color_25_to_50: "#8bb4d5",
    color_50_to_100: "#4b9ac6",
    color_100_to_200: "#108599",
    color_200_to_300: "#016f60",
    color_above_300: "#014636",
};
export const Color19 = {
    color_0_to_5: "#9e0142",
    color_5_to_10: "#e25249",
    color_10_to_25: "#fba45c",
    color_25_to_50: "#fee899",
    color_50_to_100: "#edf7a3",
    color_100_to_200: "#a1d9a4",
    color_200_to_300: "#48a0b2",
    color_above_300: "#5e4fa2",
};
export const Color20 = {
    color_0_to_5: "#a50026",
    color_5_to_10: "#e34a33",
    color_10_to_25: "#fba45c",
    color_25_to_50: "#fee899",
    color_50_to_100: "#e3f399",
    color_100_to_200: "#9cd569",
    color_200_to_300: "#3aa758",
    color_above_300: "#006837",
};
export const Color21 = {
    color_0_to_5: "#67001f",
    color_5_to_10: "#c13639",
    color_10_to_25: "#ef9b7a",
    color_25_to_50: "#fde5d6",
    color_50_to_100: "#e8e8e8",
    color_100_to_200: "#b2b2b2",
    color_200_to_300: "#656565",
    color_above_300: "#1a1a1a",
};
export const Color22 = {
    color_0_to_5: "#7f3b08",
    color_5_to_10: "#c66a0b",
    color_10_to_25: "#f8b057",
    color_25_to_50: "#fce6c8",
    color_50_to_100: "#e0e2ee",
    color_100_to_200: "#aaa2cc",
    color_200_to_300: "#664797",
    color_above_300: "#2d004b",
};
export const Color23 = {
    color_0_to_5: "#66c2a5",
    color_5_to_10: "#fc8d62",
    color_10_to_25: "#8da0cb",
    color_25_to_50: "#e78ac3",
    color_50_to_100: "#a6d854",
    color_100_to_200: "#ffd92f",
    color_200_to_300: "#e5c494",
    color_above_300: "#b3b3b3",
};
export const Color24 = {
    color_0_to_5: "#7fc97f",
    color_5_to_10: "#beaed4",
    color_10_to_25: "#fdc086",
    color_25_to_50: "#ffff99",
    color_50_to_100: "#386cb0",
    color_100_to_200: "#f0027f",
    color_200_to_300: "#bf5b17",
    color_above_300: "#666666",
};
export const Color25 = {
    color_0_to_5: "#e41a1c",
    color_5_to_10: "#3a85a8",
    color_10_to_25: "#629363",
    color_25_to_50: "#c4625d",
    color_50_to_100: "#ffc81d",
    color_100_to_200: "#bf862b",
    color_200_to_300: "#eb7aa9",
    color_above_300: "#999999",
};
export const Color26 = {
    color_0_to_5: "#8dd3c7",
    color_5_to_10: "#d9d7c9",
    color_10_to_25: "#e9877f",
    color_25_to_50: "#d9b382",
    color_50_to_100: "#c7d98c",
    color_100_to_200: "#ded7da",
    color_200_to_300: "#c2adc0",
    color_above_300: "#ffed6f",
};
export const Color27 = {
    color_0_to_5: "#1b9e77",
    color_5_to_10: "#d95f02",
    color_10_to_25: "#7570b3",
    color_25_to_50: "#e7298a",
    color_50_to_100: "#66a61e",
    color_100_to_200: "#e6ab02",
    color_200_to_300: "#a6761d",
    color_above_300: "#666666",
};
export const Color28 = {
    color_0_to_5: "#a6cee3",
    color_5_to_10: "#72b29c",
    color_10_to_25: "#4f9f3b",
    color_25_to_50: "#e93e3f",
    color_50_to_100: "#fdac4f",
    color_100_to_200: "#d1aab7",
    color_200_to_300: "#a99099",
    color_above_300: "#b15928",
};
export const Color29 = {
    color_0_to_5: "#b3e2cd",
    color_5_to_10: "#fdcdac",
    color_10_to_25: "#cbd5e8",
    color_25_to_50: "#f4cae4",
    color_50_to_100: "#e6f5c9",
    color_100_to_200: "#fff2ae",
    color_200_to_300: "#f1e2cc",
    color_above_300: "#cccccc",
};
export const Color30 = {
    color_0_to_5: "#fbb4ae",
    color_5_to_10: "#b6d1de",
    color_10_to_25: "#d1e1cd",
    color_25_to_50: "#ebd1c9",
    color_50_to_100: "#feeebb",
    color_100_to_200: "#ece3c1",
    color_200_to_300: "#f9d9e5",
    color_above_300: "#f2f2f2",
};
