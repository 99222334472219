import arrows from '../assets/img/icons/icon--arrows.svg'
import buildingAccent from '../assets/img/icons/icon--building-accent.svg'
import layers from '../assets/img/icons/icon--layers.svg'
import warning from '../assets/img/icons/icon--warning.svg'
import mapMarkerAccent from '../assets/img/icons/icon--map-marker-accent.svg'
import location from '../assets/img/icons/icon--location.svg'
import building_icon from '../assets/img/icons/icon--building.svg'
import historyDark from '../assets/img/icons/icon--history-dark.svg'
import collapse from '../assets/img/icons/icon--collapse.svg'
import cart2 from '../assets/img/icons/icon--cart copy.svg'
import React, {useEffect, useState, useContext} from 'react';
import AuthContext from '../store/auth-context';
import Web3 from 'web3'

import axios from "axios";

function BuildingInformation({ selectedBuilding , clearBuildingHandler, toggleCollapse, historyOnHandler, makeOfferOnHandler, buyNowOnHandler, maticPrice, usdtPrice , loader , historyBTN,makeofferBTN,buynowBTN , trigger , TriggerHandlerTrue}) {
    if(selectedBuilding === null ) {
        selectedBuilding = {
            BIN: "-",
        DOITT_ID: "-",
        Height: '-',
        Latitude: '-',
        Longitude: String('-'),
        Majority_Ownership_Type: "-",
        SOURCE_ID: "-",
        TerrainHeight: '-',
        Year: '-',
        length: '-',
        price:0,
        liveUSDPrice:0

        
            }
    }
    const authCtx = useContext(AuthContext)
    const isLoggedIn = authCtx.isLoggedIn
    const [building, setBuilding] = useState(selectedBuilding);
    
    
    const [txs, setTxs] = useState([]);
    const [totalCost, setTotalCost] = useState('...');
    const [totalCostUSD, setTotalCostUSD] = useState('...');
    const token_symbol = 'MATIC'
    
    useEffect(async() => {
      console.log('trigger',trigger)
      if(!trigger){
        
        console.log('fdafadsfda',selectedBuilding)
        setBuilding(selectedBuilding);
        calculateBuildingCost(selectedBuilding);
        TriggerHandlerTrue()

      }
        
      }, [selectedBuilding,trigger]);
        
      
      useEffect(() => {
        if (txs) {
            
          const obj = {
            SOURCE_ID: building['SOURCE_ID'],
            BIN: building['BIN'],
            Height: building['Height'],
            Latitude: building['Latitude'],
            Longitude: building['Longitude'],
            TerrainHeight: building['TerrainHeight'],
            txs: txs,
            totalCost: totalCost,
            // wallet: wallet,
            paymentType: 'buy'
          };
    
         
    
          // axios.post(AppUrl.order, obj, {
          //   headers: Api.getHeaders()
          // })
          //   .then(res => {
          //     toast.success(res.data.message);
          //   })
          //   .catch(e => {
          //     console.log(e);
          //   })
        }
      }, [txs]);
      async function calculateBuildingCost(selectedBuilding){
        setTotalCost('...');
        setTotalCostUSD('...');
    
        let building_height = selectedBuilding['Height'];
    //     let response = await axios.get('http://test.api.nextmetaverse.io/api/sell-building-record-total', { params: { building_height: building_height } });
    // console.log(selectedBuilding)
    //     setTotalCost(response.data.final_building_price_matic);
    //     setTotalCostUSD((response.data.final_building_price_usd));
      }
    return (
        <aside id="app-info" className="md:w-128 md:flex-none flex-auto self-stretch min-h-full  py-5 px-8 bg-glass relative z-50 info--collapsed">
        <header className="relative flex sm:flex-row flex-col sm:items-center sm:justify-between uppercase mb-6">
          <div className="flex flex-row pr-5  items-center  flex-wrap">
            <h5 className="flex items-center mr-4 mb-0 info-title">
            
             <button onClick={buyNowOnHandler} disabled={building['price']=="0"?true:false || !isLoggedIn} className="id_block hidden items-center justify-center  mr-5  text-white h-9 w-9 bg-blue-300 rounded-md" data-original-title="null">
               
                <img className="h-6 w-6 transform -rotate-90 " src={cart2} alt="Cart"/>
                
              </button>
             
           
              <img className="h-6 mr-2" src={building_icon} alt="" />
              Building selected: <div className="id_block hidden">{ building['SOURCE_ID'] }</div>
              <div onClick={clearBuildingHandler} className={`${(parseFloat(building['Height']).toFixed(2) == 'NaN') ? "hidden" : "cancel_button hidden"} cursor-pointer  h-6 w-6 justify-center items-center rounded-full bg-red-400 text-white`}><div>&#10005;</div></div>
            </h5>
            
            <button onClick={clearBuildingHandler} id="btn-clear-selection" className="text-xs underline">
              Clear selection
            </button>
          </div>
          <button onClick={toggleCollapse} id="btn-collapse-app-info" className="absolute w-6 h-6 cursor-pointer">
            <img src={collapse} alt="Toggle app info" />
          </button>
        </header>
        <div className="bg-primary rounded-lg p-12 info-content" style={{position:'relative'}}>
        {/* {loader === true &&  <LoadingSpinner />} */}
          <h2 className="mb-4">Building Name</h2>
          <ul className="flex flex-wrap bg-blue-300 rounded-lg pt-3 px-4 justify-between building-info mb-2">
            <li className="flex w-48% relative mb-4">
              <img className="w-6 h-6 mr-2" src={buildingAccent} alt="" />
              <p className="break-all"><strong><small>{ building['SOURCE_ID'] }</small></strong></p>
              <div className="tooltipsCard">Building ID</div>
            </li>
            <li className="flex  w-48% relative mb-4">
              <img className="w-6 h-6 mr-2" src={layers} alt="" />
              <p><strong><small>{(parseFloat(building['Height']).toFixed(2) == 'NaN') ? '-' : Math.round(building['Height']/3)}</small></strong></p>
              <div className="tooltipsCard">Floors</div>
            </li>
            <li className="flex  w-48% relative mb-4">
              <img className="w-6 h-6 mr-2" src={arrows} alt="" />
              <p><strong><small>{ (parseFloat(building['Height']).toFixed(2) == 'NaN') ? '-':parseFloat(building['Height']).toFixed(2)+' mtr'   }  </small></strong></p>
              <div className="tooltipsCard">Building Height</div>
            </li>
            <li className="flex  w-48% relative mb-3">
              <img className="w-6 h-6 mr-2" src={warning} alt="" />
              <p><strong><small>{building['BIN']}</small></strong></p>
              <div className="tooltipsCard">BIN Number</div>
            </li>
            <li className="flex  w-48% relative mb-3">
              <img className="w-6 h-6 mr-2" src={mapMarkerAccent} alt="" />
              <p><strong><small>Manhattan</small></strong></p>
              <div className="tooltipsCard">Borough</div>
            </li>
            <li className="flex  w-48% relative mb-2">
              <img className="w-6 h-6 mr-2" src={location} alt="" />
              <p><strong><small>{(parseFloat(building['Latitude']).toFixed(5) == "NaN") ? '' : parseFloat(building['Latitude']).toFixed(5)} {(parseFloat(building['Longitude']).toFixed(5) == "NaN") ?'-' : parseFloat(building['Longitude']).toFixed(5)}</small></strong></p>
              <div className="tooltipsCard">Latitude and Longitude</div>
            </li>
          </ul>
          <ul className="flex flex-wrap bg-blue-300 rounded-lg  pt-3 pb-2 px-4 justify-between mb-2">
            <li className="w-48% sm:mb-2">
              <p>Current Owner</p>
            </li>
            <li className="w-48% sm:mb-2 mb-3">
              <p>-</p>
              
            </li>
            <li className="w-48% sm:mb-2">
              <p>Purchased for</p>
            </li>
            <li className="w-48% sm:mb-2 mb-3">
              <p>-</p>
            </li>
            <li className="w-48% sm:mb-2">
              <p>Last offer</p>
            </li>
            <li className="w-48% sm:mb-2 mb-3">
              <p>-</p>
            </li>
          </ul>
          <div className="bg-blue-300 flex flex-col px-4 rounded-lg pt-3 pb-3 mb-3">
          <div className="flex items-center  justify-between mb-1 total-price">
            <div className="text-lg md:text-xl">$ Current Price</div>

            <h3 className="sm:mt-0 mt-2 text-accent">{maticPrice} MATIC</h3>
          </div>
          <h4 className="text-right">(${usdtPrice} USDT)</h4>
          </div>
          <button disabled={historyBTN} onClick={historyOnHandler} className={`hover:opacity-75 w-full flex items-center justify-center uppercase text-dark bg-light mb-3 sm:p-3 p-1 rounded-lg ${historyBTN == true && 'disabledBTN'}`}>
            <img className="mr-2" src={historyDark} alt="" />
            <strong>History</strong>
          </button>
          <button disabled={makeofferBTN} onClick={makeOfferOnHandler} className={`hover:opacity-75 w-full uppercase text-center text-dark bg-light mb-3 sm:p-3 p-1 rounded-lg ${makeofferBTN == true && 'disabledBTN'}`}>
            <strong>Make offer</strong>
          </button>
          <button disabled={buynowBTN} onClick={buyNowOnHandler} className={`hover:opacity-75 w-full uppercase text-center bg-gradient-light mb-5 sm:p-3 p-1 rounded-lg ${buynowBTN == true && 'disabledBTN'}`}>
            <strong>$ Buy now</strong>
          </button>
          <button onClick={clearBuildingHandler} className="w-full text-xs uppercase text-center underline">Clear selection</button>
        </div>
      </aside>
    )
}

export default BuildingInformation