function HistoryMain(props) {
  return (
          <main className={`${props.historyOnOff ? "block" : "hidden"} relative overflow-x-auto z-50 w-full  bg-glass p-16 not-sold-wrap`}>
            <div class="bg-primary max-w-6xl mx-auto w-full p-2">
        <div class=" flex items-center py-2 px-2">
            <h1 class="mr-auto">Trading history</h1>
          <div onClick={props.historyOffHandler}  class="text-gradient-light    text-3xl cursor-pointer">&#10005;</div>
         
          
          </div>
          <div class=" buyNowPopUp  w-full  mt-1 bg-primary  overflow-x-auto">
            
        <table class="table-spacing w-full">
  <thead>
    <tr>
      
      <th class="py-6 px-2 shadow-inset-card">EVENT</th>
      <th class="py-6 px-2 shadow-inset-card">PRICE</th>
      <th class="py-6 px-2 shadow-inset-card">PRICE(BNB)</th>
      <th class="py-6 px-2 shadow-inset-card">FROM</th>
      <th class="py-6 px-2 shadow-inset-card">TO</th>
      <th class="py-6 px-2 shadow-inset-card" >DATE</th>
    </tr>
  </thead>
  <tbody class="">
    <tr class="bg-blue-300 font-light">
   
    
      <th class=" py-6 px-2 font-light">Offer cancelled</th>
      <th class="py-6 px-2 font-light">
      2 MATIC
      </th>
      <th class="py-6 px-2 font-light">34.23 USDT</th>
      <th class="py-6 px-2 font-light">0.7 USDT</th>
      <th class="py-6 px-2 font-light">43.43 USDT</th>
      <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
    </tr>
    <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
   <th class=" p-3">Offer cancelled</th>
   <th class="py-6 px-2 font-light">
   2 MATIC
   </th>
   <th class="py-6 px-2 font-light">34.23 USDT</th>
   <th class="py-6 px-2 font-light">0.7 USDT</th>
   <th class="py-6 px-2 font-light">43.43 USDT</th>
   <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
 </tr>
 <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
   <tr class="bg-blue-200">
   
   
     <th class=" p-3">Offer cancelled</th>
     <th class="py-6 px-2 font-light">
     2 MATIC
     </th>
     <th class="py-6 px-2 font-light">34.23 USDT</th>
     <th class="py-6 px-2 font-light">0.7 USDT</th>
     <th class="py-6 px-2 font-light">43.43 USDT</th>
     <th class="py-6 px-2 font-light"><i class="fa-solid fa-hotel"></i></th>
   </tr>
  </tbody>
</table>
</div>
          </div>
        </main>
  )
    }

    
    export default HistoryMain