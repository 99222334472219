import logout from '../assets/img/icons/icon--logout.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useRef } from 'react'
import { useForm } from "react-hook-form";
function ForgotMyPassword(props) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const emailInputRef = useRef();
    const email = useRef({});
    const submitHandler = (event) => {
        // event.preventDefault()
        // const enteredEmail = emailInputRef.current.value;
        let enteredEmail = watch("email", "");
        
        axios.post('https://api.nextmetaverse.io/password-recovery/'+enteredEmail)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        }
    
    return (
        <div className="h-screen">
    <div className="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={logout} page="forget password"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="login"/>
      <HumMenu/>

      <main className=" w-full">
         <div className="flex h-full items-center px-5 ">
           <div className="mx-auto ">
         <div className="max-w-lg">
        <p className="text-center loginTitle mb-4">RESET PASSWORD</p>
        <p className="text-center mb-4">Enter your email address and we'll send you a link to get back into your account. </p>
        <form onSubmit={handleSubmit(submitHandler)}>
        <div className="mt-6 relative ">
        
        <label className="">Email address</label>
        <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="text" ref={emailInputRef} name="email"
                           placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })} />
        {errors.email?.type === 'required' && <div className="text-red-400 absolute left-0">You must specify an email</div>}
        {errors.email?.type === 'pattern' && <div className="text-red-400 absolute left-0">Not a valid email</div>}
    </div>
       
        <button className="hover:opacity-75  w-full uppercase text-center bg-gradient-light mt-8 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>RESET</strong>
        </button>
        </form>
        <div className="flex mt-3">
            <Link to="/auth/register" className="border-white mr-auto border-b-2 text-s">Register</Link>
            <Link to="/auth/login" className="border-white border-b-2 text-s">Login</Link>
        </div>
        </div>
        </div>
        </div>
     </main>
      
    </div>

    
  </div>
  </div>
    )
}

export default ForgotMyPassword