
import React, { useEffect, useState } from "react";
import './Cesium.css'
import * as Cesium from "cesium";// import { ChevronUp, ChevronDown } from "react-bootstrap-icons";

import {
  Color1,
  Color2,
  Color3,
  Color4,
  Color5,
  Color6,
  Color7,
  Color8,
  Color9,
  Color10,
  Color11,
  Color12,
  Color13,
  Color14,
  Color15,
  Color16,
  Color17,
  Color18,
  Color19,
  Color20,
  Color21,
  Color22,
  Color23,
  Color24,
  Color25,
  Color26,
  Color27,
  Color28,
  Color29,
  Color30,
} from "./Colors";

import axios from "axios";
function Example ({ setSelectedBuilding, selectedBuilding ,TriggerHandlerFalse}) {
  
    const selectedBuildingHandler = (building) => {
      // console.log('HERE BUILDING',building)
      // TriggerHandlerFalse()
      setSelectedBuilding(building);
    };
    const [isMount, setIsMount] = useState(false);
    const [isresult, setIsresult] = useState(0);
    const [tilesetData, setTilesetData] = useState(null);
    const [sellBuildingArrayData, setSellBuildingArrayData] = useState();
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [features, setFeatures] = useState([]);
    const [IsEnbaleColorPicker, setIsEnbaleColorPicker] = useState(false);
    const [IsEnbaleToolbar, setIsEnbaleToolbar] = useState(false);
    const [colorByHeight, setColorByHeight] = useState(Color1);
    const [height, setHeight] = useState(600);
    const [selectedFeatureColor, setSelectedFeatureColor] = useState(null);
    const [previousColor, setPreviousColor] = useState(null);
    const [IsFilterEnable, setIsFilterEnable] = useState(false);
    // const data =[
    //   "12210016235",
    //   "21210000601",
    //   "21210001155",
    //   "21210003367"
    // ]
    const toggleToolbar = () => {
      if (IsEnbaleToolbar) {
        document.getElementById("toolbarPanel").style.display = "none";
        document.getElementById("legend").style.display = "none";
        setIsEnbaleToolbar(false);
      } else {
        document.getElementById("toolbarPanel").style.display = "block";
        setIsEnbaleToolbar(true);
      }
    };
  
    const OnChangeHandler = (event) => {
      if (event.target.value === "Source") {
        setIsFilterEnable(false);
        SetBuildingDefaultColor();
        document.getElementById("legend").style.display = "none";
      } else if (event.target.value === "price") {
        setIsFilterEnable(true);
        SetBuildingColorByPrice(colorByHeight);
        document.getElementById("legend").style.display = "block";
        document.getElementById("heightSlider").value = "400";
        document.getElementById("heightValue").innerHTML = "&nbsp; All";
      }
    };
  
    const HeightChangeHandler = (event) => {
      FilterbuildingByHeight(Number(event.target.value));
      if (Number(event.target.value > 390)) {
        setHeight(600);
        document.getElementById("heightValue").innerHTML = "&nbsp; All";
      } else {
        setHeight(event.target.value);
        document.getElementById("heightValue").innerHTML =
          "&lt; " + event.target.value + "m";
      }
    };
  
    const HideDistanceHandler = () => {
      if (document.getElementById("HideDistance").checked) {
        tilesetData.maximumScreenSpaceError = 64;
      } else {
        tilesetData.maximumScreenSpaceError = 16;
      }
    };
  
    const toggleColorPicker = () => {
      if (IsEnbaleColorPicker) {
        document.getElementsByClassName(
          "palette-picker-dropDown"
        )[0].style.display = "none";
        setIsEnbaleColorPicker(false);
      } else {
        document.getElementsByClassName(
          "palette-picker-dropDown"
        )[0].style.display = "flex";
        setIsEnbaleColorPicker(true);
      }
    };
  
    const handleColorPicker = (url, color_code) => {
      document.getElementsByClassName("palette-picker")[0].style.backgroundImage =
        "url(" + url + ")";
      setColorByHeight(color_code);
      SetBuildingColorByPrice(color_code);
      UpdateLegendColor(color_code);
    };
  
    const UpdateLegendColor = (colorByHeight) => {
      document.getElementById("color_0_to_5").style.background =
        colorByHeight.color_0_to_5;
      document.getElementById("color_5_to_10").style.background =
        colorByHeight.color_5_to_10;
      document.getElementById("color_10_to_25").style.background =
        colorByHeight.color_10_to_25;
      document.getElementById("color_25_to_50").style.background =
        colorByHeight.color_25_to_50;
      document.getElementById("color_50_to_100").style.background =
        colorByHeight.color_50_to_100;
      document.getElementById("color_100_to_200").style.background =
        colorByHeight.color_100_to_200;
      document.getElementById("color_200_to_300").style.background =
        colorByHeight.color_200_to_300;
      document.getElementById("color_above_300").style.background =
        colorByHeight.color_above_300;
    };
  
    const SetBuildingDefaultColor = () => {
      tilesetData.style = new Cesium.Cesium3DTileStyle({
        show:
          "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606 &&Number(${Height}) <" +
          Number(height),
        color: {
          evaluateColor: function (feature, result) {
            if (feature instanceof Cesium.Cesium3DTileFeature) {
              var featureColor;
              if (typeof feature.getProperty("SOURCE_ID") != "undefined") {
                var SOURCE_ID = feature.getProperty("SOURCE_ID");
                featureColor = getFeatureColor(true, SOURCE_ID);
              }
              return featureColor;
            }
          },
        },
      });
    };
  
    const SetBuildingColorByPrice = (Color) => {
      tilesetData.style = new Cesium.Cesium3DTileStyle({
        show:
          "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606 &&Number(${Height}) <" +
          Number(height),
        color: {
          evaluateColor: function (feature, result) {
            if (feature instanceof Cesium.Cesium3DTileFeature) {
              var featureColor;
              if (typeof feature.getProperty("SOURCE_ID") != "undefined") {
                var SOURCE_ID = feature.getProperty("SOURCE_ID");
                var Height = feature.getProperty("Height");
                featureColor = getFeatureColor(false, SOURCE_ID, Height, Color);
              }
              return featureColor;
            }
          },
        },
      });
    };
  
    const FilterbuildingByHeight = (height) => {
      if (IsFilterEnable) {
        var Color = colorByHeight;
        if (height > 390) {
          tilesetData.style = new Cesium.Cesium3DTileStyle({
            show: "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606",
            color: {
              evaluateColor: function (feature, result) {
                if (feature instanceof Cesium.Cesium3DTileFeature) {
                  var featureColor;
                  if (typeof feature.getProperty("SOURCE_ID") != "undefined") {
                    var SOURCE_ID = feature.getProperty("SOURCE_ID");
                    var Height = feature.getProperty("Height");
                    featureColor = getFeatureColor(
                      false,
                      SOURCE_ID,
                      Height,
                      colorByHeight
                    );
                  }
                  return featureColor;
                }
              },
            },
          });
        } else {
          tilesetData.style = new Cesium.Cesium3DTileStyle({
            show:
              "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606 &&Number(${Height}) <" +
              Number(height),
            color: {
              evaluateColor: function (feature, result) {
                if (feature instanceof Cesium.Cesium3DTileFeature) {
                  var featureColor;
                  if (typeof feature.getProperty("SOURCE_ID") != "undefined") {
                    var SOURCE_ID = feature.getProperty("SOURCE_ID");
                    var Height = feature.getProperty("Height");
                    featureColor = getFeatureColor(
                      false,
                      SOURCE_ID,
                      Height,
                      colorByHeight
                    );
                  }
                  return featureColor;
                }
              },
            },
          });
        }
      } else {
        if (height > 390) {
          tilesetData.style = new Cesium.Cesium3DTileStyle({
            show: "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606",
            color: {
              evaluateColor: function (feature, result) {
                if (feature instanceof Cesium.Cesium3DTileFeature) {
                  var featureColor;
                  if (typeof feature.getProperty("BIN") != "undefined") {
                    var BIN = feature.getProperty("BIN");
                    if (sellBuildingArrayData.includes(BIN.toString())) {
                      featureColor= Cesium.Color.fromCssColorString("GREEN");
                    }
                    else{
                      featureColor= Cesium.Color.fromCssColorString("white");
                    }
                  }
                  return featureColor;
                }
              },
            },
          });
        } else {
          tilesetData.style = new Cesium.Cesium3DTileStyle({
            show:
              "Number(${feature['BIN']}) >= 1000000 && Number(${feature['BIN']}) <= 1300000&& Number(${feature['Latitude']}) >= 40.70115086268407 && Number(${feature['Latitude']}) <= 40.874065178019606 &&Number(${Height}) <" +
              Number(height),
              color: {
                evaluateColor: function (feature, result) {
                  if (feature instanceof Cesium.Cesium3DTileFeature) {
                    var featureColor;
                    var BIN = feature.getProperty("BIN");
                    if (sellBuildingArrayData.includes(BIN.toString())) {
                      featureColor= Cesium.Color.fromCssColorString("GREEN");
                    }
                    else{
                      featureColor= Cesium.Color.fromCssColorString("white");
                    }
                    return featureColor;
                  }
                },
              },
          });
        }
      }
    };
  
    function getFeatureColor(
      isDefaultSource = true,
      BIN = null,
      Height = null,
      colorByHeight
    ) {
      let featureColor = Cesium.Color.fromCssColorString("WHITE");
      /*check in sell building*/
      if (BIN && sellBuildingArrayData.includes(BIN.toString())) {
        return Cesium.Color.fromCssColorString("GREEN");
      }
  
      /*check by height*/
      if (Height) {
        if (Number(Height) > 0 && Number(Height) <= 5) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_0_to_5
          );
        } else if (Number(Height) > 5 && Number(Height) <= 10) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_5_to_10
          );
        } else if (Number(Height) > 10 && Number(Height) <= 25) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_10_to_25
          );
        } else if (Number(Height) > 25 && Number(Height) <= 50) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_25_to_50
          );
        } else if (Number(Height) > 50 && Number(Height) <= 100) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_50_to_100
          );
        } else if (Number(Height) > 100 && Number(Height) <= 200) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_100_to_200
          );
        } else if (Number(Height) > 200 && Number(Height) <= 300) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_200_to_300
          );
        } else if (Number(Height) > 300) {
          featureColor = Cesium.Color.fromCssColorString(
            colorByHeight.color_above_300
          );
        }
      }
  
      return featureColor;
    }
  
    useEffect(() => {
      // Grant CesiumJS access to your ion assets
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMzI5ZTM1Zi1hZWFhLTRiMDItOWVmOC0yYTUxODMzOWM2NmMiLCJpZCI6Njc3NTYsImlhdCI6MTY0MTI0OTE2N30.O1ntZefUSwklPAo1IsAnqn5_L4z6Lrb4-6P4P7kNUoQ";
  
     
       const viewer = new Cesium.Viewer("cesiumContainer", {
           skyAtmosphere: false,
           shouldAnimate: true,
           terrainProvider: new Cesium.CesiumTerrainProvider({
             url: Cesium.IonResource.fromAssetId(1),
           }),
           scene3DOnly: true,
           geocoder: false,
           infoBox: false,
           animation: false,
           timeline: false,
           fullscreenButton: false,
           baseLayerPicker: false,
           homeButton: false,
           
         });
        //  viewer.extend(Cesium.viewerCesiumNavigationMixin, {});
      // Cesium.defineProperties = Object.defineProperties;
      
      
      viewer._cesiumWidget._creditContainer.style.display = "none";
      var globe = viewer.scene.globe;
      var scene = viewer.scene;
      viewer.scene.light = new Cesium.DirectionalLight({
        direction: viewer.scene.camera.directionWC,
      });
      viewer.scene.screenSpaceCameraController.minimumZoomDistance = 1;
      viewer.scene.screenSpaceCameraController.maximumZoomDistance = 25000;
  
      var silhouetteGreen =
        Cesium.PostProcessStageLibrary.createEdgeDetectionStage();
      silhouetteGreen.uniforms.color = Cesium.Color.LIME;
      silhouetteGreen.uniforms.length = 0.1;
      silhouetteGreen.selected = [];
  
      viewer.scene.postProcessStages.add(
        Cesium.PostProcessStageLibrary.createSilhouetteStage([silhouetteGreen])
      );
     
      var viewModel = {
        clippingPlanesEnabled: true,
        edgeStylingEnabled: true,
        height: 400,
        useBrowserRecommendedResolution: true,
        resolutionScale: 1.0,
      };
  
      if (!isMount) {
        Cesium.knockout.track(viewModel);
        var Resolutiontoolbar = document.getElementById("Resolutiontoolbar");
        Cesium.knockout.applyBindings(viewModel, Resolutiontoolbar);
        for (var name in viewModel) {
          if (viewModel.hasOwnProperty(name)) {
            Cesium.knockout.getObservable(viewModel, name).subscribe(update);
          }
        }
  
        function update() {
          viewer.useBrowserRecommendedResolution =
            viewModel.useBrowserRecommendedResolution;
  
          var resolutionScale = Number(viewModel.resolutionScale);
          resolutionScale = !isNaN(resolutionScale) ? resolutionScale : 1.0;
          resolutionScale = Cesium.Math.clamp(resolutionScale, 0.7, 2.0);
          viewer.resolutionScale = resolutionScale;
        }
  
        update();
        // For tracking state when switching exampleTypes
        var clippingPlanesEnabled = true;
        var edgeStylingEnabled = true;
        var tileset;
  
        loadSellTileRecordData();
        loadManhattanIsland();
        setInitialView();
  
        function loadSellTileRecordData() {
          // const sellBuildingArray = axios.get(json);
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
          fetch("https://api.nextmetaverse.io/buildings/", requestOptions)
            .then(response => response.json())
            .then((result) =>{
              const sellBuildingArray = result.map(String);
              // console.log("sellBuildingArray", result)
              // setSellBuildingArrayData(sellBuildingArray.data);
              // InitializeTileset(sellBuildingArray.data);
              setSellBuildingArrayData(sellBuildingArray);
              InitializeTileset(sellBuildingArray);
            })
            .catch(error => console.log('error', error));
        }
  
        function InitializeTileset(sellBuildingArray) {
          tileset = new Cesium.Cesium3DTileset({
            url: Cesium.IonResource.fromAssetId(75343),
          });
          setTilesetData(tileset);
          viewer.scene.primitives.add(tileset);
          tileset.tileLoad.addEventListener(function (tile) {
            var content = tile.content;
            var featuresLength = content.featuresLength;
            for (var i = 0; i < featuresLength; i++) {
              var feature = content.getFeature(i);
              var BIN = feature.getProperty("BIN");
              var lon = feature.getProperty("Longitude");
              var lat = feature.getProperty("Latitude");
              var SOURCE_ID = feature.getProperty("SOURCE_ID");
              if (
                Number(BIN) >= 1000000 &&
                Number(BIN) <= 1300000 &&
                Number(lat) >= 40.70115086268407 &&
                Number(lat) <= 40.874065178019606
              ) {
                features.push(feature);
                feature.show = true;
                if (sellBuildingArray.includes(BIN.toString())) {
                  feature.color = Cesium.Color.GREEN;
                }
              } else {
                feature.show = false;
              }
            }
          });
        }
  
        function setInitialView() {
          // Set the initial camera view to look at Manhattan
          var initialPosition = Cesium.Cartesian3.fromDegrees(
            -74.01881302800248,
            40.69114333714821,
            753
          );
          var initialOrientation = new Cesium.HeadingPitchRoll.fromDegrees(
            21.27879878293835,
            -21.34390550872461,
            0.0716951918898415
          );
          viewer.scene.camera.setView({
            destination: initialPosition,
            orientation: initialOrientation,
            endTransform: Cesium.Matrix4.IDENTITY,
          });
        }
  
        function loadManhattanIsland() {
          // Pick a position at the Grand Canyon
          var position = Cesium.Cartographic.toCartesian(
            new Cesium.Cartographic.fromDegrees(-73.99069484, 40.739333, 100)
          );
          var boundingSphere = new Cesium.BoundingSphere(position, 3000);
  
          globe.clippingPlanes = new Cesium.ClippingPlaneCollection({
            modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(position),
            planes: [
              new Cesium.ClippingPlane(
                new Cesium.Cartesian3(1.0, 0.0, 0.0),
                3500
              ),
              new Cesium.ClippingPlane(
                new Cesium.Cartesian3(-1.0, 0.0, 0.0),
                8000
              ),
              new Cesium.ClippingPlane(
                new Cesium.Cartesian3(0.0, 1.0, 0.0),
                5500
              ),
              new Cesium.ClippingPlane(
                new Cesium.Cartesian3(0.0, -1.0, 0.0),
                18000
              ),
            ],
            unionClippingRegions: true,
            edgeWidth: edgeStylingEnabled ? 1.0 : 0.0,
            edgeColor: Cesium.Color.WHITE,
            enabled: clippingPlanesEnabled,
          });
          globe.backFaceCulling = false;
          globe.showSkirts = false;
  
          viewer.camera.viewBoundingSphere(
            boundingSphere,
            new Cesium.HeadingPitchRange(0.5, -0.5, boundingSphere.radius * 5.0)
          );
          viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
        }
      }
  
      var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      // Information about the currently highlighted feature
      var highlighted = {
        feature: undefined,
        originalColor: undefined,
      };
  
      var selected = {
        feature: undefined,
        originalColor: undefined,
      };
  
      handler.setInputAction(function (movement) {
        // If a feature was previously highlighted, undo the highlight
        if (Cesium.defined(highlighted.feature)) {
          highlighted.feature.color = highlighted.originalColor;
          highlighted.feature = undefined;
        }
        // Click on new elements
        var pickedFeature = viewer.scene.pick(movement.endPosition);
        if (!Cesium.defined(pickedFeature)) {
          return;
        }
        // Highlight the feature if it's not already selected.
        if (pickedFeature !== selected.feature) {
          highlighted.feature = pickedFeature;
          highlighted.originalColor = pickedFeature.color;
          Cesium.Color.clone(pickedFeature.color, highlighted.originalColor);
          pickedFeature.color = Cesium.Color.YELLOW;
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.setInputAction(function (event) {
        silhouetteGreen.selected = [];
        // If you previously selected a feature, unhighlight it
        if (Cesium.defined(selected.feature)) {
          selected.feature.color = selected.originalColor;
          selected.feature = undefined;
        }
        // Click on new elements
        var pickedFeature = viewer.scene.pick(event.position);
        if (!Cesium.defined(pickedFeature)) {
          return;
        }
        // Select the feature if it's not already selected
        if (selected.feature === pickedFeature) {
          return;
        }
        selected.feature = pickedFeature;
        // Save the selected feature's original color
        if (pickedFeature === highlighted.feature) {
          setPreviousColor(highlighted.originalColor);
          selected.originalColor = highlighted.originalColor;
          Cesium.Color.clone(highlighted.originalColor, selected.originalColor);
          highlighted.feature = undefined;
        } else {
          setPreviousColor(pickedFeature.color);
          selected.originalColor = pickedFeature.color;
          Cesium.Color.clone(pickedFeature.color, selected.originalColor);
        }
        // Highlight newly selected feature
        pickedFeature.color = Cesium.Color.GREEN;
        // Select the feature if it's not already selected
        if (silhouetteGreen.selected[0] === pickedFeature) {
          return;
        }
  
        // Highlight newly selected feature
        silhouetteGreen.selected = [pickedFeature];
        setSelectedFeatureColor(silhouetteGreen);
        setSelectedFeature(pickedFeature);
  
        var propertyNames = pickedFeature.getPropertyNames();
        var length = propertyNames.length;
        var buildingData = [];
        for (var i = 0; i < length; ++i) {
          var propertyName = propertyNames[i];
          buildingData[propertyName] = pickedFeature.getProperty(propertyName);
        }
        selectedBuildingHandler(buildingData);
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
  
      if (!isMount) {
        setIsMount(true);
      }
    }, [IsFilterEnable]);
  
    useEffect(() => {
      if (isMount && selectedBuilding == null) {
        deselectBuilding();
      }
    }, [selectedBuilding]);
  
    function deselectBuilding() {
      var BIN = selectedFeature.getProperty("BIN");
      if (sellBuildingArrayData.includes(BIN.toString())) {
        selectedFeature.color = Cesium.Color.GREEN;
        selectedFeatureColor.selected = [];
      } else {
        selectedFeatureColor.selected = [];
        selectedFeature.color = previousColor;
        setPreviousColor(null);
        setSelectedFeature(null);
      }
    }
  
    return (
      <>
        <div id="cesiumContainer">
          <div id="toolbar" className="hidden">
            Show / Hide Toolbox &nbsp;{" "}
            {/* {IsEnbaleToolbar ? <ChevronUp /> : <ChevronDown />} */}
          </div>
          <div id="toolbarPanel" className="hidden mx-auto left-14  lg:-left-5 lg:right-72">
            <div>Filters</div>
            <div id="styleConfig">
              <input
                type="radio"
                id="defaultColor"
                name="color"
                value="Source"
                onChange={OnChangeHandler}
                defaultChecked
              />
              <label htmlFor="defaultColor">&nbsp;&nbsp;Source colors</label>
              <br></br>
              <input
                type="radio"
                id="colorByPrice"
                name="color"
                value="price"
                onChange={OnChangeHandler}
              />
              <label htmlFor="colorByPrice">&nbsp;&nbsp;Color by price</label>
              <div id="heightToolbar">
                <label id="heightSliderLabel" htmlFor="heightSlider">
                  Heights
                </label>
                <input
                  id="heightSlider"
                  type="range"
                  min="10"
                  max="400"
                  step="10"
                  value={height}
                  onChange={HeightChangeHandler}
                />
                <label id="heightValue">&nbsp;All</label>
              </div>
              <div id="Resolutiontoolbar">
                <label id="resolutionSliderLabel" htmlFor="resolutionSlider">
                  Resolution Scale
                </label>
                <input
                  id="resolutionSlider"
                  type="range"
                  min="0.1"
                  max="2.0"
                  step="0.1"
                  data-bind="value: resolutionScale, valueUpdate: 'input'"
                />
                <input
                  id="resolutionValue"
                  type="text"
                  size="5"
                  
                  data-bind="value: resolutionScale"
                />
              </div>
              <div id="HideDistancetoolbar">
                <label id="HideDistanceLabel" htmlFor="HideDistance">
                  Hide Distance building
                </label>
                <input
                  id="HideDistance"
                  type="checkbox"
                  onChange={HideDistanceHandler}
                />
              </div>
            </div>
          </div>
          <div id="legend" className="legend backdrop mx-auto left-14 lg:-left-24  lg:right-72">
            <b>Prices by Height</b>
  
            <div id="legend-items">
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_0_to_5"
                  style={{ background: "#fff7ec" }}
                />
                0 to 5 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_5_to_10"
                  style={{ background: "#fde5c2" }}
                />
                5 to 10 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_10_to_25"
                  style={{ background: "#fdcc96" }}
                />
                10 to 25 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_25_to_50"
                  style={{ background: "#fca771" }}
                />
                25 to 50 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_50_to_100"
                  style={{ background: "#f4764f" }}
                />
                50 to 100 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_100_to_200"
                  style={{ background: "#dd3f2a" }}
                />
                100 to 200 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_200_to_300"
                  style={{ background: "#b80604" }}
                />
                200 to 300 meters
              </div>
              <div>
                <span
                  className="legend-colorBlock"
                  id="color_above_300"
                  style={{ background: "#7f0000" }}
                />
                Above 300 meters
              </div>
            </div>
            <div id="pallet-picker-label" className="nowrap">
              Palette:
              <div id="palette-picker-container" onClick={toggleColorPicker}>
                <button
                  type="button"
                  className="palette-picker"
                  data-bind="click: toggleDropDown, style: { backgroundImage: 'url(\'' + selectedItem().image() + '\')' }"
                  style={{
                    backgroundImage:
                      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftlyEOgDAQBKf//xIChSCBEBQWJGAw4Cgv2AeQTO2YNm1vdkt9zkpY7z4mBNscWe2HyI5ujWxppsja6857AYoH8UZ8Wv4RnFqOXz2iEI0oZi1DIyhE+4jFyoaIQlSICvFnQvwAxYvOd7iDU0IAAAAASUVORK5CYII=')",
                  }}
                ></button>
                <div className="palette-picker-dropDown">
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftlyEOgDAQBKf//xIChSCBEBQWJGAw4Cgv2AeQTO2YNm1vdkt9zkpY7z4mBNscWe2HyI5ujWxppsja6857AYoH8UZ8Wv4RnFqOXz2iEI0oZi1DIyhE+4jFyoaIQlSICvFnQvwAxYvOd7iDU0IAAAAASUVORK5CYII=",
                        Color1
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftlyEOgDAQBKf//xIChSCBEBQWJGAw4Cgv2AeQTO2YNm1vdkt9zkpY7z4mBNscWe2HyI5ujWxppsja6857AYoH8UZ8Wv4RnFqOXz2iEI0oZi1DIyhE+4jFyoaIQlSICvFnQvwAxYvOd7iDU0IAAAAASUVORK5CYII=')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftl7ENgCAURI/9p3EDZqCxIVhoiImNhMLCRIwD3AAmj/ZV5MN/d2Fc95A5694dUtpOy2Jtlk35sGzJxbJnjpZ9IHARJsLT4o+IrcX6xSMIkYhC1iI0SgiRPkKxoiEKISJEhPgzIb7mo+lSRxWIwAAAAABJRU5ErkJggg==",
                        Color2
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftl7ENgCAURI/9p3EDZqCxIVhoiImNhMLCRIwD3AAmj/ZV5MN/d2Fc95A5694dUtpOy2Jtlk35sGzJxbJnjpZ9IHARJsLT4o+IrcX6xSMIkYhC1iI0SgiRPkKxoiEKISJEhPgzIb7mo+lSRxWIwAAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKgDAQBDf//5UIAVttFUXBdIKNBKIRH7APECbtVOGSm91wlbvKnGk/HdIwH5atfbLsiZtluRkt65bWsg8ELsJEeFr8EbG1WL94BCESUchahEYJIdJHKFY0RCFEhIgQfybEFzjS6ejmvhK9AAAAAElFTkSuQmCC",
                        Color3
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKgDAQBDf//5UIAVttFUXBdIKNBKIRH7APECbtVOGSm91wlbvKnGk/HdIwH5atfbLsiZtluRkt65bWsg8ELsJEeFr8EbG1WL94BCESUchahEYJIdJHKFY0RCFEhIgQfybEFzjS6ejmvhK9AAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGZJREFUWEft1rENgCAYROGH+8+krZUJCZ1xACMtrTjBDWDyaK8gBPi/K3M8k7Deu6UIzjVnbYtZrzVmxzVitvcl7wcUD+KN+LT8Izi1HL86IohWFLuWpREEEUEUREEUREEUxJ+B+AHFHqCqmtMmqAAAAABJRU5ErkJggg==",
                        Color4
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGZJREFUWEft1rENgCAYROGH+8+krZUJCZ1xACMtrTjBDWDyaK8gBPi/K3M8k7Deu6UIzjVnbYtZrzVmxzVitvcl7wcUD+KN+LT8Izi1HL86IohWFLuWpREEEUEUREEUREEUxJ+B+AHFHqCqmtMmqAAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftlzEOgCAQBJf/f8nGB4jRaE8hoaFFG43GB+wDSIZ2KnJwsxuu+3llTj6bQ4o1WTYcq2VLGi3b9tmyNhXLfhC4CBPhafFHxNZi/eIRhEhEIWsRGiWESB+hWNEQhRARIkLsTIgfGi3kV5cqu7AAAAAASUVORK5CYII=",
                        Color5
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftlzEOgCAQBJf/f8nGB4jRaE8hoaFFG43GB+wDSIZ2KnJwsxuu+3llTj6bQ4o1WTYcq2VLGi3b9tmyNhXLfhC4CBPhafFHxNZi/eIRhEhEIWsRGiWESB+hWNEQhRARIkLsTIgfGi3kV5cqu7AAAAAASUVORK5CYII=')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGhJREFUWEft1iEOgDAQRNHfcP/7IJAISA+Aw5AaHAK9nGAOQPJrRzRN233TqkYRVj1biuCcc9bXmF19xGw/3pgt95T3A5oH8UZ8Wv4RnFqOXx0RRCuKXcvSCIKIIAqiIAqiIAriz0D8AEkHnjldIYD0AAAAAElFTkSuQmCC",
                        Color6
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGhJREFUWEft1iEOgDAQRNHfcP/7IJAISA+Aw5AaHAK9nGAOQPJrRzRN233TqkYRVj1biuCcc9bXmF19xGw/3pgt95T3A5oH8UZ8Wv4RnFqOXx0RRCuKXcvSCIKIIAqiIAqiIAriz0D8AEkHnjldIYD0AAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKwCAQBNf/vynkAyFNLISUJqQI2FlYXcgD9gHC2A4Wot7spog7ZFYbm0Pa38uypXq2ltPuy8djWc/Nsh8kDsKN8LT4I2JqMX7xCEIkopC1CI0SQqSPUKxoiEKICBEhTibED6L85c6St+3yAAAAAElFTkSuQmCC",
                        Color7
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKwCAQBNf/vynkAyFNLISUJqQI2FlYXcgD9gHC2A4Wot7spog7ZFYbm0Pa38uypXq2ltPuy8djWc/Nsh8kDsKN8LT4I2JqMX7xCEIkopC1CI0SQqSPUKxoiEKICBEhTibED6L85c6St+3yAAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlisOgDAQBV/vfx80poLQkHAAECgcNa1kGw7wDkAya0dt9jMvRashU++5O6Qos2frYtmdD8u2q1o2Pd2yDyQaYSKsFjcivhbvF48gRCIKWYvQKCHEQIgIESEiRISIEH8mxAFwl+ZkbPXtIwAAAABJRU5ErkJggg==",
                        Color8
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlisOgDAQBV/vfx80poLQkHAAECgcNa1kGw7wDkAya0dt9jMvRashU++5O6Qos2frYtmdD8u2q1o2Pd2yDyQaYSKsFjcivhbvF48gRCIKWYvQKCHEQIgIESEiRISIEH8mxAFwl+ZkbPXtIwAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlzEOgCAQBJf/P8ngK4wloaEwxsJYUMEZH7APMBnaqcjBzW6K/oTMGbU6pLk1y0Y+LNuXy7K1nJ7FbdkHEhdhIjwt/ojYWqxfPIIQiShkLUKjhBDpIxQrGqIQIkJEiD8T4gspr+yLOaApjAAAAABJRU5ErkJggg==",
                        Color9
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlzEOgCAQBJf/P8ngK4wloaEwxsJYUMEZH7APMBnaqcjBzW6K/oTMGbU6pLk1y0Y+LNuXy7K1nJ7FbdkHEhdhIjwt/ojYWqxfPIIQiShkLUKjhBDpIxQrGqIQIkJEiD8T4gspr+yLOaApjAAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftlzEKgDAQBDf/f5aFlQghYCNoIbFKeaQRFB+wDwhM2iFFSHKzm/oTr8y64nRIuWXL5rpaNu2HZWXzLJbb7vtB4iDcCE+LPyKmFuMXjyBEIgpZi9AoIUT6CMWKhiiEiBAR4mBC/AC+y+RXpqe9bQAAAABJRU5ErkJggg==",
                        Color10
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftlzEKgDAQBDf/f5aFlQghYCNoIbFKeaQRFB+wDwhM2iFFSHKzm/oTr8y64nRIuWXL5rpaNu2HZWXzLJbb7vtB4iDcCE+LPyKmFuMXjyBEIgpZi9AoIUT6CMWKhiiEiBAR4mBC/AC+y+RXpqe9bQAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7kNwCAQBJeEyP0X4QacWSKlAyRb8lcC+VkUsAUgDelE6OBmN0W0kDl3bw5pe7Nl67VYdtZuWS6PZd+xWzZA4iJMhKfFHxFbi/WLRxAiEYWsRWiUECJ9hGJFQxRCRIgIcTIh/sS1nEUIouneAAAAAElFTkSuQmCC",
                        Color11
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7kNwCAQBJeEyP0X4QacWSKlAyRb8lcC+VkUsAUgDelE6OBmN0W0kDl3bw5pe7Nl67VYdtZuWS6PZd+xWzZA4iJMhKfFHxFbi/WLRxAiEYWsRWiUECJ9hGJFQxRCRIgIcTIh/sS1nEUIouneAAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKgDAQBDf//5eVZYpgIUIEJUlhYaei+IB9QGDSThUuudkN9/W8MqfV0yHNy2FZiptlUyyW5SFbto67ZT8IXISJ8LT4I2JrsX7xCEIkopC1CI0SQqSPUKxoiEKICBEhdibED7SY7WzqHBrdAAAAAElFTkSuQmCC",
                        Color12
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftlzEKgDAQBDf//5eVZYpgIUIEJUlhYaei+IB9QGDSThUuudkN9/W8MqfV0yHNy2FZiptlUyyW5SFbto67ZT8IXISJ8LT4I2JrsX7xCEIkopC1CI0SQqSPUKxoiEKICBEhdibED7SY7WzqHBrdAAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftmLEKgCAURa9b9P//5CThWltBDS0KhYtQ9AH3A4LjeiZR37nX0Hp5ZNZ6bQ5pqYdl8Rw9mwfLctotu9Nk2QcCG+FEuFq8ETG1GL94BCESUSJZi9AohEgfoVjREIUQESK/KPqXEF/RV7yxIjIhuAAAAABJRU5ErkJggg==",
                        Color13
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEftmLEKgCAURa9b9P//5CThWltBDS0KhYtQ9AH3A4LjeiZR37nX0Hp5ZNZ6bQ5pqYdl8Rw9mwfLctotu9Nk2QcCG+FEuFq8ETG1GL94BCESUSJZi9AohEgfoVjREIUQESK/KPqXEF/RV7yxIjIhuAAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGBJREFUWEftl8EJADEIBDf9lyaCWED+VmG4AraAg8l3XsHE2T27uzJnZhzSvdeyqrKsuy2LCMsy07IPHC7CRHha/BGxtVi/eAQhElHIWoRGCSHSRyhWNEQhRISIEH8mxAcLzNsQEsI8bwAAAABJRU5ErkJggg==",
                        Color14
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGBJREFUWEftl8EJADEIBDf9lyaCWED+VmG4AraAg8l3XsHE2T27uzJnZhzSvdeyqrKsuy2LCMsy07IPHC7CRHha/BGxtVi/eAQhElHIWoRGCSHSRyhWNEQhRISIEH8mxAcLzNsQEsI8bwAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftl7EJgDAUBS/g/qtYuoFYBEvB1kZcQGzkO8EbQLi01yQk+fdeq+pFWHXOCcG+RFbrEdk9XpFt/Y1seoa8F6B5EG/Ep+Ufwanl+NUjCtGIYtYyNIJCtI9YrGyIKESFqBB/JsQPCq65RgOrAxIAAAAASUVORK5CYII=",
                        Color15
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGpJREFUWEftl7EJgDAUBS/g/qtYuoFYBEvB1kZcQGzkO8EbQLi01yQk+fdeq+pFWHXOCcG+RFbrEdk9XpFt/Y1seoa8F6B5EG/Ep+Ufwanl+NUjCtGIYtYyNIJCtI9YrGyIKESFqBB/JsQPCq65RgOrAxIAAAAASUVORK5CYII=')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEdj/P7l538GHODl/fe4pBjenH2KU0746GOccv/3nMApd+bJRZxye39fwykHkmAc9chojIwmrdE8wjBaao0Wv6P1yGiFONpEGW1rjTYaGRhGK8TR/shox2q0h8gwWiGOVoijFeIQqxABbWvPJsP+cFUAAAAASUVORK5CYII=",
                        Color16
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEdj/P7l538GHODl/fe4pBjenH2KU0746GOccv/3nMApd+bJRZxye39fwykHkmAc9chojIwmrdE8wjBaao0Wv6P1yGiFONpEGW1rjTYaGRhGK8TR/shox2q0h8gwWiGOVoijFeIQqxABbWvPJsP+cFUAAAAASUVORK5CYII=')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlyEOwCAQBJf/f6quBlkECoM6hyBtgqDpA/YBTQY7QRDgZjfda2+Z1WI6pBLDsqN5lmvYffXqlj3ltOwDiYNwIzwt/oiYWoxfPIIQiShkLUKjhBDpIxQrGqIQIkJEiD8T4gtZ/Or72X4CbQAAAABJRU5ErkJggg==",
                        Color17
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGlJREFUWEftlyEOwCAQBJf/f6quBlkECoM6hyBtgqDpA/YBTQY7QRDgZjfda2+Z1WI6pBLDsqN5lmvYffXqlj3ltOwDiYNwIzwt/oiYWoxfPIIQiShkLUKjhBDpIxQrGqIQIkJEiD8T4gtZ/Or72X4CbQAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7kNgEAMBOf6L4cQUQIJxAgJxJNCQkBwVLAFIM2lKweWfZ7dUp+3Et66XUliOO6oNXuu65Y51k1nGzXGPmtAsREn4mr5R/BqeX7liEDUoui1NI0gEM0jBisTIgJRIArEnwHxAwDTzkVQAjSzAAAAAElFTkSuQmCC",
                        Color18
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7kNgEAMBOf6L4cQUQIJxAgJxJNCQkBwVLAFIM2lKweWfZ7dUp+3Et66XUliOO6oNXuu65Y51k1nGzXGPmtAsREn4mr5R/BqeX7liEDUoui1NI0gEM0jBisTIgJRIArEnwHxAwDTzkVQAjSzAAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjnMfo9J8BB/COYcUlxSCSqIFTjskxAKfc/mcPccpNO8mLU45r4VecciAJxlGPjMbIaNIazSMMo6XWaPE7Wo+MVoijTZTRttZoo5GBYbRCHO2PjHasRnuIDKMV4miFOFohDrEKEQBKEOi4NMahuQAAAABJRU5ErkJggg==",
                        Color19
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjnMfo9J8BB/COYcUlxSCSqIFTjskxAKfc/mcPccpNO8mLU45r4VecciAJxlGPjMbIaNIazSMMo6XWaPE7Wo+MVoijTZTRttZoo5GBYbRCHO2PjHasRnuIDKMV4miFOFohDrEKEQBKEOi4NMahuQAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjXMqg9p8BB3CNEMYlxSCcZoZTjskxAKfcjofbcMpNvfAcp9yWHbdxyoEkGEc9Mhojo0lrNI8wjJZao8XvaD0yWiGONlFG21qjjUYGhtEKcbQ/MtqxGu0hMoxWiKMV4miFOMQqRABJAwmX33cQpwAAAABJRU5ErkJggg==",
                        Color20
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjXMqg9p8BB3CNEMYlxSCcZoZTjskxAKfcjofbcMpNvfAcp9yWHbdxyoEkGEc9Mhojo0lrNI8wjJZao8XvaD0yWiGONlFG21qjjUYGhtEKcbQ/MtqxGu0hMoxWiKMV4miFOMQqRABJAwmX33cQpwAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7ENgDAMBD8dWyDRMwcZgT4StGzlynN4rCAG+AGQLu1VkRPff7u1Tpkzjs0h7c9p2dIvy6rKssy0LCIs+0DjIkyEp8UfEVuL9YtHECIRhaxFaJQQIn2EYkVDFEJEiAjxZ0J8AWEiAa7JhxmTAAAAAElFTkSuQmCC",
                        Color21
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGtJREFUWEftl7ENgDAMBD8dWyDRMwcZgT4StGzlynN4rCAG+AGQLu1VkRPff7u1Tpkzjs0h7c9p2dIvy6rKssy0LCIs+0DjIkyEp8UfEVuL9YtHECIRhaxFaJQQIn2EYkVDFEJEiAjxZ0J8AWEiAa7JhxmTAAAAAElFTkSuQmCC')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjrLfm+M+AAwRZsuOSYtAJzMcp98uoEqfc8cOPccptnn8Mp9ye5atxyoEkGEc9Mhojo0lrNI8wjJZao8XvaD0yWiGONlFG21qjjUYGhtEKcbQ/MtqxGu0hMoxWiKMV4miFOMQqRAD2VB7IU/v5vgAAAABJRU5ErkJggg==",
                        Color22
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjrLfm+M+AAwRZsuOSYtAJzMcp98uoEqfc8cOPccptnn8Mp9ye5atxyoEkGEc9Mhojo0lrNI8wjJZao8XvaD0yWiGONlFG21qjjUYGhtEKcbQ/MtqxGu0hMoxWiKMV4miFOMQqRAD2VB7IU/v5vgAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjTDu09D8DDtB87hwuKYZDHKE45aQlD+OWU9+AU47jvQdOuVNvDHDKgSQYRz0yGiOjSWs0jzCMllqjxe9oPTJaIY42UaRH21qjjUaG0QpxtD8y2rEa7SEyjFaIoxXi6CgKw9CqEAG7nRWBcjOuugAAAABJRU5ErkJggg==",
                        Color23
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjTDu09D8DDtB87hwuKYZDHKE45aQlD+OWU9+AU47jvQdOuVNvDHDKgSQYRz0yGiOjSWs0jzCMllqjxe9oPTJaIY42UaRH21qjjUaG0QpxtD8y2rEa7SEyjFaIoxXi6CgKw9CqEAG7nRWBcjOuugAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjrD9Z/58BB/B84YFLisFU5ShOuXkPJHDKiRe8xiknqj4Tp9x8KTucciAJxlGPjMbIaNIazSMMo6XWaPE7Wo+MVoijTZTRttZoo5GBYbRCHO2PjHasRnuIDKMV4miFOFohDrEKEQDPvv7jA0538gAAAABJRU5ErkJggg==",
                        Color24
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjrD9Z/58BB/B84YFLisFU5ShOuXkPJHDKiRe8xiknqj4Tp9x8KTucciAJxlGPjMbIaNIazSMMo6XWaPE7Wo+MVoijTZTRttZoo5GBYbRCHO2PjHasRnuIDKMV4miFOFohDrEKEQDPvv7jA0538gAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjfCIl858BB4gqKcclxeDw4BNOuUbBapxyj9z1ccr9XhOLU26vOi9OOZAE46hHRmNkNGmN5hGG0VJrtPgdrUdGK8TRJspoW2u00cjAMFohjvZHRjtWoz1EhtEKcbRCHK0Qh1iFCACStw3jdxbcxQAAAABJRU5ErkJggg==",
                        Color25
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjfCIl858BB4gqKcclxeDw4BNOuUbBapxyj9z1ccr9XhOLU26vOi9OOZAE46hHRmNkNGmN5hGG0VJrtPgdrUdGK8TRJspoW2u00cjAMFohjvZHRjtWoz1EhtEKcbRCHK0Qh1iFCACStw3jdxbcxQAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdj7L18/D8DDmD9iQeXFMPD2/dxyulpbsYpJ8lRg1Pu3IG7OOUcYzbilANJMI56ZDRGRpPWaB5hGC21Rovf0XpktEIcbaKMtrVGG40MDKMV4mh/ZLRjNdpDZBitEEcrxNEKcYhViACPdzTzGRTuuwAAAABJRU5ErkJggg==",
                        Color26
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdj7L18/D8DDmD9iQeXFMPD2/dxyulpbsYpJ8lRg1Pu3IG7OOUcYzbilANJMI56ZDRGRpPWaB5hGC21Rovf0XpktEIcbaKMtrVGG40MDKMV4mh/ZLRjNdpDZBitEEcrxNEKcYhViACPdzTzGRTuuwAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjlJ5X/p8BB1h7eAUuKYbL39twynHzr8MpJ+q0A6fc16u8OOW2vfDDKQeSYBz1yGiMjCat0TzCMFpqjRa/o/XIaIU42kQZbWuNNhoZGEYrxNH+yGjHarSHyDBaIY5WiKMV4hCrEAEYrjDyYDV/pQAAAABJRU5ErkJggg==",
                        Color27
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdjlJ5X/p8BB1h7eAUuKYbL39twynHzr8MpJ+q0A6fc16u8OOW2vfDDKQeSYBz1yGiMjCat0TzCMFpqjRa/o/XIaIU42kQZbWuNNhoZGEYrxNH+yGjHarSHyDBaIY5WiKMV4hCrEAEYrjDyYDV/pQAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEdjXHbu8X8GHODz/3W4pBjkzz/GKWdx4SJOub/KTDjlpp4Lwimn/6cfpxxIgnHUI6MxMpq0RvMIw2ipNVr8jtYjoxXiaBNltK012mhkYBitEEf7I6Mdq9EeIsNohThaIY5WiEOsQgQA2UFi8pmaB84AAAAASUVORK5CYII=",
                        Color28
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEdjXHbu8X8GHODz/3W4pBjkzz/GKWdx4SJOub/KTDjlpp4Lwimn/6cfpxxIgnHUI6MxMpq0RvMIw2ipNVr8jtYjoxXiaBNltK012mhkYBitEEf7I6Mdq9EeIsNohThaIY5WiEOsQgQA2UFi8pmaB84AAAAASUVORK5CYII=')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdj3Pzo7H8GHMDxzUtcUgxPmA1wyonyfcApxynyEKfc348COOVuvmDFKQeSYBz1yGiMjCat0TzCMFpqjRa/o/XIaIU42kQZbWuNNhoZGEYrxNH+yGjHarSHyDBaIY5WiKMV4hCrEAFLDTXU6lDt6QAAAABJRU5ErkJggg==",
                        Color29
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAGxJREFUWEdj3Pzo7H8GHMDxzUtcUgxPmA1wyonyfcApxynyEKfc348COOVuvmDFKQeSYBz1yGiMjCat0TzCMFpqjRa/o/XIaIU42kQZbWuNNhoZGEYrxNH+yGjHarSHyDBaIY5WiKMV4hCrEAFLDTXU6lDt6QAAAABJRU5ErkJggg==')",
                    }}
                  ></div>
  
                  <div
                    className="palette-picker-choice"
                    onClick={() =>
                      handleColorPicker(
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEftl7EJgDAUBS/gCgoWFiLYuLuVA7iAc7iCIBoneAMIl/aKEJL8e6/c21oJa5+WhBjeiJibI8KrHyOrZxvZ03d5Q6B4EG/Ep+Ufwanl+NUjCtGIYtYyNIJCtI9YrGyIKESFqBB/JsQPhcgO9t/cXrEAAAAASUVORK5CYII=",
                        Color30
                      )
                    }
                    style={{
                      backgroundImage:
                        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAG1JREFUWEftl7EJgDAUBS/gCgoWFiLYuLuVA7iAc7iCIBoneAMIl/aKEJL8e6/c21oJa5+WhBjeiJibI8KrHyOrZxvZ03d5Q6B4EG/Ep+Ufwanl+NUjCtGIYtYyNIJCtI9YrGyIKESFqBB/JsQPhcgO9t/cXrEAAAAASUVORK5CYII=')",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default Example