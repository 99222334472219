import warning from '../assets/img/icons/icon--warning.svg'

function MakeOfferMain(props) {
    
    return (
<main className={`${props.makeOfferOnOff ? "flex" : "hidden"} relative z-50 flex-auto h-auto bg-glass pt-16 pb-6 not-sold-wrap`}>
        
        <div class="w-11/12 max-w-6xl mx-auto buyNowPopUp overflow-y-auto custom-scrollbar  relative  xl:px-20 md:px-16 px-4 md:py-12 py-6 mt-1 bg-primary not-sold">
        
        <div class=" flex items-center py-2 px-2">
            <h1 class="mr-auto">Make an offer!</h1>
          <div onClick={props.makeOfferOffHandler}  class="text-gradient-light    text-3xl cursor-pointer">&#10005;</div>
         
          
          </div>
          <div class="p-3 bg-blue-300 rounded-lg shadow-inset-card mb-4 lg:flex lg:space-x-3">
            
          <img class="w-24 h-24 rounded-md shadow-md mb-2 lg:mb-0" src="https://storage.googleapis.com/cdn-pub-production-nextearth-io/data/5b025fba-88bf-439e-87ae-3f87df8fc1ef/static-map/dc8e9810-a684-40de-886e-edf327ec8204.jpg"></img>
          <div class="flex flex-wrap justify-between lg:w-full">
          <div class="">
          <div class="opacity-50 ">Item</div>
          <div class="text-2xl font-bold">Budavári Palota, Budapest</div>
            <div class="flex items-center space-x-2 mb-2 lg:mb-0">
            <img class="w-6 h-6 max-w-56 max-h-56 rounded-full border-2 border-white shadow-md" src="https://storage.googleapis.com/cdn-pub-production-nextearth-io/data/5b025fba-88bf-439e-87ae-3f87df8fc1ef/static-map/dc8e9810-a684-40de-886e-edf327ec8204.jpg"></img>
            <div class="opacity-50">Current owner</div>
            <div class="font-bold">zsefs</div>
            </div>
            </div>
            <div class="flex flex-col ">
                <div class="opacity-50">Purchased price</div>
                <div class="text-2xl font-bold">64.4 USDT</div>
            </div>
            </div>

            </div>
            <div class="lg:grid lg:grid-cols-3 lg:gap-1 ">
            <div class="p-2 lg:p-4 flex items-center w-full shadow-inset-card bg-blue-300 font-bold rounded-t-lg lg:rounded-t-none lg:rounded-tl-lg mb-1">AMOUNT (MATIC)</div>
            <div class="bg-blue-200 p-2 lg:p-4  w-full mb-1">
            <input class="w-full bg-blue-200  " type="number" placeholder="Whrite the offer in MATIC"></input>
            <ul >
                <li class="text-red-400">Offer is required</li>
            </ul>
            </div>
            <div class="bg-blue-200 w-full p-2 lg:p-4 flex items-center mb-1 ">0 USDT</div>
            </div>
            <div class="lg:grid lg:grid-cols-3 lg:gap-1">
            <div class="p-2 lg:p-4 shadow-inset-card lg:flex items-center font-bold bg-blue-300  mb-1">EXPIRES IN (DAYS)</div>
            <div class="bg-blue-200 p-2 lg:p-4 mb-1 lg:col-span-2 lg:flex items-center">30 days</div>
            </div>
            <label class="xl:w-full mt-4 flex items-center " for="tos">
              <input id="tos" class="min-w-5 h-5 mr-3 flex self-center rounded appearance-none" type="checkbox" name="tos" />
              <p><strong>I have read and accept terms and conditions</strong></p>
            </label>
            <button class="hover:opacity-75   w-full uppercase text-center bg-gradient-light mt-4 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>SEND OFFER</strong>
          </button>
          <div class="py-4 flex">
          <img class="w-6 h-6 mr-2" src={warning} alt="" />
              <div>By sending this offer, you accept that we will put this amount into a smart contract deposit, until it is accepted or you cancel your offer.</div>
              </div>
            {/* <label class="xl:w-full flex items-center " for="tos">
              <input id="tos" class="min-w-5 h-5 mr-3 flex self-center rounded appearance-none" type="checkbox" name="tos" />
              <p><strong>I have read and accept terms and conditions</strong></p>
            </label>
            
        
          <button class="hover:opacity-75   w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>SEND OFFER</strong>
          </button>
          By sending this offer, you accept that we will put this amount into a smart contract deposit, until it is accepted or you cancel your offer. */}
        </div>
      </main>
    )
}

export default MakeOfferMain