import { useState, useEffect } from "react";

function ChangePass(props) {
    return (
       
        <div className={`${!props.show ? "opacity-1 visible" : "opacity-0 invisible"} transition-all duration-500 block bg-blue-300 md:bg-black md:bg-opacity-80 h-screen w-full fixed inset-0 flex justify-center items-center z-2000`}>
        <div class="bg-primary p-5 rounded-md h-screen md:h-auto w-full md:max-w-2xl md:relative fixed inset-0 md:top-0 z-2000">
            <div class="px-4 md:py-4">
                <div class="flex items-center mt-10 md:mt-0">
                
           <div class="flex mr-auto items-center space-x-4">
          
               <h1 class=""> CHANGE PASSWORD</h1>
              
               </div> 
               <div onClick={props.handleClose2}  class="cursor-pointer"><i class="text-3xl fa-solid fa-xmark"></i></div>
               </div>
               
               <div class=" ">
            <label class="">Current password</label>
            <input class="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="confirmpassword"
                               /> 
        </div>
               <div class=" ">
            <label class="">New password</label>
            <input class="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="confirmpassword"
                               /> 
        </div>
        <p class="text-sm ">Passwords must be at least 8 characters with one uppercase letter, one number, and one special character.</p>
        <ul class="text-red-400">
            <li>Username is required.</li>
        </ul>
        <div class=" ">
            <label class="">Confirm new password</label>
            <input class="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="confirmpassword"
                               /> 
        </div>
        <button class="hover:opacity-75  w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg" onclick="window.location.href='02-not-sold-yet.html'">
            <strong>SAVE</strong>
        </button>
        </div>
        </div>
        </div>
    )
}

export default ChangePass