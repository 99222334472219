// import classes from './Layout.module.css'
import { useState, useEffect, useContext } from "react";
import arrows from '../assets/img/icons/icon--arrows.svg'
import buildingAccent from '../assets/img/icons/icon--building-accent.svg'
import layers from '../assets/img/icons/icon--layers.svg'
import warning from '../assets/img/icons/icon--warning.svg'
import mapMarkerAccent from '../assets/img/icons/icon--map-marker-accent.svg'
import location from '../assets/img/icons/icon--location.svg'
import building from '../assets/img/icons/icon--building.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import share from '../assets/img/icons/icon--share.svg'
import edit from '../assets/img/icons/icon--edit.svg'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
function BuldingIdPage(props) {
  const authCtx = useContext(AuthContext)
   useEffect(() => {
      
      if(Cookies.get('access_token') === undefined) {
        authCtx.logout();
    }
      },[])
  const image1 = require('../assets/img/maps/map--small-1.jpg')
      const size = useWindowSize()
    return (
        <div className="h-screen">
    <div className="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={building} page="building name"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar />
      <HumMenu/>

      <main className="main flex-auto h-fit min-h-full bg-glass py-12 territory">
        <article className="w-11/12 xl:max-w-5xl  flex md:flex-row flex-col items-start bg-primary mx-auto mb-8 p-5 rounded-lg table-container territory-first">
          <img className="md:w-64 w-56 lg:h-64 object-cover md:mr-6 md:mb-0 mb-6 rounded-lg map-img" src={String(image1)} alt="Map view of property"/>
          <div className="w-full">
            <div className="flex flex-row items-center  lg:mb-4 mb-5 territory-content">
              <h2 className="lg:mr-4 sm:mr-2 mr-0 pr-2">Building Name</h2>
              <div className="flex flex-auto  md:mb-0 items-center ">
               
                <div className="flex items-center w-full">
                  <img className="mr-auto" src={edit} alt="Edit building name"/>
                  <img src={share} alt="Share"/>
                </div>
              </div>
            </div>
            <div className=" w-full flex flex-wrap territory-item-wrap">
              <div className=" flex flex-wrap">
                <div className="w-full lg:w-1/2 mb-4">
              <ul className="flex flex-wrap  rounded-lg h-full p-3 bg-blue-300 ">
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={buildingAccent} alt=""/>
                  <p><strong><small>12356238560</small></strong></p>
                </li>
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={layers} alt=""/>
                  <p><strong><small>24</small></strong></p>
                </li>
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={arrows} alt=""/>
                  <p><strong><small>125 mtr</small></strong></p>
                </li>
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={warning} alt=""/>
                  <p><strong><small>1002345</small></strong></p>
                </li>
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={mapMarkerAccent} alt=""/>
                  <p><strong><small>Manhattan</small></strong></p>
                </li>
                <li className="flex w-1/2 mb-4">
                  <img className="mr-2" src={location} alt=""/>
                  <p><strong><small>42.123 65.2345</small></strong></p>
                </li>
              </ul>
              </div>
              <div className=" lg:pl-5  w-full lg:w-1/2 mb-4">
              <ul className="bg-blue-300 flex self-start h-full flex-1  items-center flex-wrap rounded-lg p-3">
                
                    <li className="w-full flex mb-4">
                      <p className="w-1/2"><small>Current Owner</small></p>
                      <p className="w-1/2"><small>-</small></p>
                    </li>
                    
                    <li className="w-full flex mb-4">
                      <p className="w-1/2"><small>Purchased for</small></p>
                      <p className="w-1/2"><small>-</small></p>
                    </li>
                    <li className="w-full flex mb-4">
                      <p className="w-1/2"><small>Last offer</small></p>
                      <p className="w-1/2"><small>-</small></p>
                    </li>
                  </ul>
                  </div>
              <button className="w-full md:w-2/3 lg:w-1/2  uppercase text-center bg-gradient-light sm:p-3 p-2 rounded-lg">
                <strong>$ Put it up for sale</strong>
              </button>
              </div>
              
            </div>
          </div>
        </article>

        <article className="xl:w-11/12 xl:max-w-5xl mx-auto mb-8 table-container">
          <div className="w-full max-w-7xl bg-gradient-light p-3 rounded-t-lg">
            <div className="flex items-center">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-3"><path d="M12.8975 21.968C12.3665 21.9696 11.857 21.7586 11.4825 21.382L3.64854 13.547C3.23513 13.1348 3.02315 12.5621 3.06854 11.98L3.56854 5.41401C3.63984 4.4264 4.42674 3.64163 5.41454 3.57301L11.9805 3.07301C12.0315 3.06201 12.0835 3.06201 12.1345 3.06201C12.6644 3.06337 13.1722 3.27399 13.5475 3.64801L21.3825 11.482C21.7578 11.8571 21.9686 12.3659 21.9686 12.8965C21.9686 13.4271 21.7578 13.9359 21.3825 14.311L14.3115 21.382C13.9374 21.7583 13.4282 21.9693 12.8975 21.968ZM12.1335 5.06201L5.56254 5.56201L5.06254 12.133L12.8975 19.968L19.9675 12.898L12.1335 5.06201ZM8.65454 10.654C7.70038 10.6542 6.87895 9.98037 6.69262 9.04458C6.50629 8.10879 7.00695 7.17169 7.88841 6.80639C8.76987 6.44109 9.78663 6.74933 10.3169 7.54259C10.8471 8.33586 10.7431 9.39322 10.0685 10.068C9.69437 10.4443 9.18522 10.6553 8.65454 10.654Z" fill="white"></path></svg>
            <h4>Offers</h4>
            </div>
          </div>
          <div className="xl:overflow-visible overflow-hidden rounded-b-lg">
            <table className="w-full table-fixed text-left bg-gradient-dark rounded-b-lg">
              <thead>
                <tr>
                  <th className="uppercase m:p-5 px-3 py-3 bg-gradient-dark">
                    <h5>No Offers Yet</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="m:p-4 p-3"> </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>

        <article className="xl:w-11/12 xl:max-w-5xl mx-auto mb-8 table-container table-container-trading">
          <div className="w-full max-w-7xl bg-gradient-light p-3 rounded-t-lg">
            <div className="flex items-center">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-3"><path d="M9 20L4 16L9 12V15H22V17H9V20ZM15 12V9H2V7H15V4L20 8L15 12Z" fill="white"></path></svg>
              <h4>Trading History</h4>
            </div>
          </div>
          <div className="xl:overflow-visible overflow-scroll overflow-y-hidden rounded-b-lg custom-scrollbar">
            <table className="w-full table-fixed text-center overflow-hidden bg-gradient-dark rounded-b-lg building-id">
              <thead>
                <tr>
                  <th className="uppercase md:px-3 md:py-5 p-3 bg-gradient-dark mediam-text">
                    <h5>Event</h5>
                  </th>
                  <th className="uppercase p-3 bg-gradient-dark mediam-text">
                    <h5>Price</h5>
                  </th>
                  <th className="uppercase p-3 bg-gradient-dark mediam-text">
                    <h5>Price (BNB)</h5>
                  </th>
                  <th className="uppercase p-3 bg-gradient-dark mediam-text">
                    <h5>From</h5>
                  </th>
                  <th className="uppercase p-3 bg-gradient-dark mediam-text">
                    <h5>To</h5>
                  </th>
                  <th className="uppercase p-3 bg-gradient-dark mediam-text">
                    <h5>Date</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="m:p-4 p-3">Transfer</td>
                  <td className="m:p-4 p-3">-</td>
                  <td className="m:p-4 p-3">-</td>
                  <td className="m:p-4 p-3 break-words">0X0000...000000</td>
                  <td className="m:p-4 p-3">maxtorgr</td>
                  <td className="m:p-4 p-3">2 months ago</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="m:p-4 p-3">Transfer</td>
                  <td className="m:p-4 p-3">-</td>
                  <td className="m:p-4 p-3">-</td>
                  <td className="m:p-4 p-3 break-words">0X0000...000000</td>
                  <td className="m:p-4 p-3">maxtorgr</td>
                  <td className="m:p-4 p-3">2 months ago</td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </main>
      
    </div>

    
  </div>
  </div>
    )
}
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      // Add event listener
      window.addEventListener("resize", handleResize);
  
      // Call handler right away so state gets updated with initial window size
      handleResize();
  
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
   
    if (document.getElementById('btn-collapse-app-info') != null) {
      const appInfo = document.getElementById('app-info')
      // console.log(appInfo)
    if (window.innerWidth < 768) {
      appInfo.classList.add('info--collapsed');
    } else if(window.innerWidth > 767){
      appInfo.classList.remove('info--collapsed');
    }
    }
    return windowSize;
  }
export default BuldingIdPage