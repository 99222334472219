import {useState, useEffect, useContext} from "react";
import arrows from '../assets/img/icons/icon--arrows.svg'
import buildingAccent from '../assets/img/icons/icon--building-accent.svg'
import layers from '../assets/img/icons/icon--layers.svg'
import warning from '../assets/img/icons/icon--warning.svg'
import building from '../assets/img/icons/icon--building.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import Card from '../components/Card'
import value from '../assets/img/icons/icon--value.svg'
import cart from '../assets/img/icons/icon--cart.svg'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
function MapPage(props) {
   const image5 = require('../assets/img/maps/map--small-5.jpg')
   const authCtx = useContext(AuthContext)
   useEffect(() => {
      
      if(Cookies.get('access_token') === undefined) {
        authCtx.logout();
    }
      },[])
   function toggleCollapse() {
      const appInfo = document.getElementById('app-info');
      appInfo.classList.toggle('info--collapsed');
   }

   function openNav() {
      var bodyClass = document.querySelector('body')
      bodyClass.classList.toggle('closed')
   }

   function closeNav() {
      var bodyClass = document.querySelector('body')
      bodyClass.classList.remove('closed')
   }

   function notSoldYet() {

      if (window.innerWidth < 768) {
         const appInfo = document.getElementById('app-info');
         appInfo.classList.toggle('info--collapsed');
      }
      var notSoldYet = document.querySelector('.not-sold-wrap')
      notSoldYet.classList.add('flex')
      notSoldYet.classList.remove('hidden')
   }

   function notSoldYetClose() {
      var notSoldYet = document.querySelector('.not-sold-wrap')
      if (window.innerWidth < 768) {
         const appInfo = document.getElementById('app-info');
         appInfo.classList.toggle('info--collapsed');
      }
      notSoldYet.classList.remove('flex')
      notSoldYet.classList.add('hidden')
   }

   const size = useWindowSize()
   return (
      <div>
         <TopNavBar icon={building} page="my buildings"/>
         <LeftNavBar page="buildings"/>
         <HumMenu/>
         <main className="main flex-auto h-fit min-h-full  bg-glass py-8  my-building-info">
            <div className="relative max-w-7xl mx-auto flex flex-wrap md:justify-start justify-between mt-8 md:mt-0 mb-8 md:pb-12 ">
               <div className="md:absolute right-0 mx-6 lg:w-1/4 md:w-31% sm:w-48% w-full bg-dark px-6 py-3 rounded-lg">
                  <select className="w-full font-light bg-dark">
                     <option>Sort by Height</option>
                     <option>Sort by Floors</option>
                  </select>
               </div>
            </div>
            
            <div className=" w-full max-w-7xl px-6  mx-auto grid grid-cols-1 content-center gap-4 sm:grid-cols-2 md:gird-cols-3 lg:grid-cols-4 ">
                     
                     <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
           <Card image5={image5} buildingAccent={buildingAccent}
           layers={layers} arrows={arrows} warning={warning} cart={cart}
           value={value}
           />
                     </div>

           
         </main>
         {/*</div>*/}

         {/*</div>*/}
      </div>
   )
}

// Hook
// const appInfo = document.getElementById('app-info');

function useWindowSize() {

   // const appInfo = document.getElementById('app-info');
   // const appInfo = document.getElementById('app-info');
   // Initialize state with undefined width/height so server and client renders match
   // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
   const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
   });

   useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
         // Set window width/height to state
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      }

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
   }, []); // Empty array ensures that effect is only run on mount

   if (document.getElementById('btn-collapse-app-info') != null) {
      const appInfo = document.getElementById('app-info')
      // console.log(appInfo)
      if (window.innerWidth < 768) {
         appInfo.classList.add('info--collapsed');
      } else if (window.innerWidth > 767) {
         appInfo.classList.remove('info--collapsed');
      }
   }
   return windowSize;
}


// const appInfo = document.getElementById('app-info');
// function checkScreenSize() {
//   if (window.innerWidth < 768) {
//     appInfo.classList.add('info--collapsed');
//   } else if(window.innerWidth > 767){
//     appInfo.classList.remove('info--collapsed');
//   }
// }

// checkScreenSize();

export default MapPage