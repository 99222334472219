import React, { useContext, useState, useEffect } from "react";
import Cookies from 'js-cookie'
import map from '../../assets/img/icons/icon--map.svg'
import building from '../../assets/img/icons/icon--building.svg'
import store from '../../assets/img/icons/icon--store.svg'
import logout from '../../assets/img/icons/icon--log-out.svg'
import login from '../../assets/img/icons/icon--log-in.svg'
import upload from '../../assets/img/icons/icon--upload.svg'
import notification from '../../assets/img/icons/icon--notification.svg'
import account from '../../assets/img/icons/icon--account.svg'
import briefcase from '../../assets/img/icons/icon--briefcase.svg'
import history from '../../assets/img/icons/icon--history.svg'
import { Link } from 'react-router-dom'
import AuthContext from "../../store/auth-context";
function LeftNavBar(props) {
  const authCtx = useContext(AuthContext)
  // console.log(authCtx)

  useEffect(() => { 

      fetch("https://api.nextmetaverse.io/buildings/matic_price/")
      .then(response => response.json())
      .then((result) =>{
          
        window.localStorage.setItem('usdt_price', result.usdt);
        var inFifteenMinutes = new Date(new Date().getTime() + 5 * 600 * 10000);
        Cookies.set('usdt_price', result.usdt, {expires: inFifteenMinutes })
        // console.log('result',result.usdt)

        

      })
      
  }, []);







  const isLoggedIn = authCtx.isLoggedIn
    function openNav() {
        var bodyClass =  document.querySelector('body')
        bodyClass.classList.toggle('closed') 
      }
      const logoutHandler = () => {
        authCtx.logout()
      }
 return (
<aside id="app-menu" class="bg-primary h-full flex flex-col self-stretch justify-between md:pt-0 pt-6">
        <nav class=" w-full">
          
          <ul class="flex flex-col items-center space-y-3 relative mt-5">
            <li class="h-8">
            <button onClick={openNav} id="" class=" group text-white text-ne-deepPurple nav-button relative "><div class="group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 "><div><svg class="w-6 h-6 flex-shrink-0 fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19H5L5.007 12H7L7 17H12V19ZM18.992 12H17V7L12 7V5L19 5L18.992 12Z"></path>
            </svg>
            </div></div><span class="ml-4 font-bold text-sm uppercase"></span> <div class="tooltips">Toggle menu</div>
          </button>
            </li>
            <li class="h-8">
              <Link  to="/" className={'flex items-center group cursor-pointer text-white has-tooltip'} class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'map') ? "bg-ne-deepPurple" : ""}`}><div><img class="h-6 w-6" src={map} alt="Marker on map"/>
              </div></div></Link>
              <div class="tooltips">Map</div>
            </li>
            <li class="h-8">
              <Link to="/buildings" className={`flex items-center group cursor-pointer text-white has-tooltip bg-ne-deepPurple `}  class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'buildings') ? "bg-ne-deepPurple" : ""}`}><div>
                <img class="h-6 w-6" src={building} alt="Buildings"/>
              </div></div></Link>
              <div class="tooltips">My Buildings</div>
            </li>
            <li class="h-8">
              <Link to="/marketplace" className={'flex items-center group cursor-pointer text-white has-tooltip' } class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'marketplace') ? "bg-ne-deepPurple" : ""}`}><div>
                <img class="h-6 w-6" src={store} alt="Store"/>
              </div></div></Link>
              <div class="tooltips">Marketplace</div>
            </li>
            <li class="h-8">
            <Link to="/leaderboard" className={'flex items-center group cursor-pointer text-white has-tooltip' } class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'leaderboard') ? "bg-ne-deepPurple" : ""}`}><div>
                <svg class="w-6 h-6 flex-shrink-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 21H2L2 9H7.5L7.5 21ZM14.75 3L9.25 3L9.25 21H14.75L14.75 3ZM22 11L16.5 11V21L22 21L22 11Z" fill="white"></path>
                  </svg>
              </div></div></Link>
              <div class="tooltips">Leaderboard</div>
            </li>
            <li class="h-8">
              <a href="#" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div class="group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div>
                <img class="h-6 w-6" src={briefcase} alt="Briefcase"/>
              </div></div></a>
              <div class="tooltips">Jobs</div>
            </li>
            <li class="h-8">
              <Link to="/history" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'history') ? "bg-ne-deepPurple" : ""}`}><div>
                <img class="h-6 w-6" src={history} alt="History"/>
              </div></div></Link>
              <div class="tooltips">History</div>
            </li>
          </ul>
        </nav>
        <nav class=" pt-16 pb-7 w-full">
          <ul class="flex flex-col items-center space-y-2 relative">
            <li class="h-8">
              <a href="#" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div class="group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300"><div><img class="h-6 w-6" src={notification} alt="Notifications"/>
                </div></div></a>
                <div class="tooltips">Notifications</div>
              
            </li>
            { isLoggedIn && <li class="h-8">
              <Link to="/account" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'account') ? "bg-ne-deepPurple" : ""}`}><div>
                <img class="h-6 w-6" src={account} alt="Account"/>
              </div></div></Link>
              <div class="tooltips">Profile</div>
            </li>}
            <li class="h-8">
              <a href="01-map-page-v1.html" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div class="group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 "><div>
                <img class="h-6 w-6" src={upload} alt="Upload"/>
              </div></div></a>
              <div class="tooltips">Homepage</div>
            </li>
            {isLoggedIn && (
            <li onClick={logoutHandler}  class="h-8">
              <Link to="/auth/login" class="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'login') ? "bg-ne-deepPurple" : ""}`}><div>
                <img class="h-6 w-6" src={logout} alt="Logout"/>
              </div></div></Link>
              <div class="tooltips">Logout</div>
            </li>
            )}
             {!isLoggedIn && (
            <li className="h-8">
              <Link to="/auth/login" className="flex items-center group cursor-pointer text-white has-tooltip" data-original-title="null"><div className={`group-hover:bg-gradient-light group-hover:bg-opacity-50 w-9 h-9 rounded-full flex items-center justify-center transition ease-in-out duration-300 ${(props.page == 'login') ? "bg-ne-deepPurple" : ""}`}><div>
              <img className="h-6 w-6" src={login} alt="Login"/>
              </div></div></Link>
              <div class="tooltips">Login</div>
            </li>
            )}
          </ul>
        </nav>
      </aside>
 )
}

export default LeftNavBar