import logout from '../assets/img/icons/icon--logout.svg'
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useRef, useContext } from 'react';
import AuthContext from '../store/auth-context';
function Register(props) {


  const navigate = useNavigate();
  const userName = useRef()
  const firstName = useRef()
  const lastName = useRef()
  const emailInputRef = useRef()
  const password = useRef()
  const confirmPassword = useRef()
  const [emailInputRes, setEmailInputRes] = useState("");
  const [passwordInputRes, setPasswordInputRes] = useState("");
  const [confirmpasswordRes, setConfirmPasswordRes] = useState("");
  const [userNameRes, setUserNameRes] = useState("");
  const [firstNameRes, setFirstNameRes] = useState("");
  const [lastNameRes, setLastNameRes] = useState("");
  const [responseAjax, setResponseAjax] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const authCtx = useContext(AuthContext)
  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };
  // console.log(authCtx)
  const submitRegister = (event) => {
    event.preventDefault()
    const enteredUserName = userName.current.value
    const enteredFirstName = firstName.current.value
    const enteredLastName = lastName.current.value
    const enteredEmail = emailInputRef.current.value
    const enteredPassword = password.current.value
    const enteredConfirmPassword = confirmPassword.current.value
    if (enteredUserName == "") {
      setUserNameRes(false);
    } else {
      setUserNameRes(true);
    }
    if (enteredFirstName == "") {
      setFirstNameRes(false);
    } else {
      setFirstNameRes(true);
    }
    if (enteredLastName == "") {
      setLastNameRes(false);
    } else {
      setLastNameRes(true);
    }
    if (enteredEmail == "") {
      setEmailInputRes(false);
    } else {
      setEmailInputRes(true);
    }
    if (enteredPassword == "") {
      setPasswordInputRes(false);
    } else {
      let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
      if(!regularExpression.test(enteredPassword)) {
        setPasswordInputRes("notmatched");
      }else{
        setPasswordInputRes(true);
      }
    }
    if (enteredConfirmPassword == "") {
      setConfirmPasswordRes(false);
    } else {
      if(enteredPassword===enteredConfirmPassword){
        setConfirmPasswordRes("matched");
      }else{
        setConfirmPasswordRes("notmatched");
      }
    }
    if (enteredEmail != "" && enteredPassword != "" && userName != "" && firstName != "" && lastName != "" && enteredConfirmPassword != "" && confirmpasswordRes === "matched" && passwordInputRes === true) {
      setIsLoading(true)
      let login_url = 'https://api.nextmetaverse.io/login/access-token';
      let reg_url = 'https://api.nextmetaverse.io/users/open';


      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "username": enteredUserName,
        "first_name": enteredFirstName,
        "last_name": enteredLastName,
        "email": enteredEmail,
        "password": enteredPassword,
      });

      // login
      let urlencoded = new URLSearchParams();
      urlencoded.append("username", enteredEmail);
      urlencoded.append("password", enteredPassword);
      // login

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(reg_url, requestOptions)
        .then(result => {
          // console.log(result)
          // setIsLoading(false)
          if (result.ok) {
            result.json().then(data => {
              // setResponseAjax(true);
              // console.log(data);
              // Login 
              // Login 
              fetch(login_url,
                {
                  method: 'POST',
                  body: urlencoded,
                  redirect: 'follow',
                  header: {
                    'Content-Type' : 'application/x-www-form-urlencoded'
                  }
                }).then(res => {
                  // console.log(res)
                  setIsLoading(false)
                  if(res.ok) {
                    res.json().then(data => {
                        const expirationTime = new Date(new Date().getTime() + 300000)
                     authCtx.login(data.access_token, expirationTime.toISOString())
                     setResponseAjax(true);
                     navigate("/history");
                    })
                    
          
                  } else {
                    res.json().then(data => {
                      setResponseAjax(false);
                    })
                  }
                })
                .catch((err) => {
                  console.log(err.message)
                })
              // Login 
              // Login 
            })


          } else {
            result.json().then(data => {
              // console.log(data);
              setResponseAjax(false);
            })
          }
        })
        .catch(error => console.log('error', error));


    }
  }

  const matchConfirmPassword = (event) =>{
    event.preventDefault()
    let matchPassword = password.current.value
    let matchConfPassword = confirmPassword.current.value
    if(matchConfPassword!=""){
      if(matchPassword===matchConfPassword){
        setConfirmPasswordRes("matched");
      }else{
        setConfirmPasswordRes("notmatched");
      }
    }else{
      setConfirmPasswordRes(false);
    }
  }
  const correctPassword = (event) =>{
    event.preventDefault()
    let regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    let matchPassword = password.current.value
    let matchConfPassword = confirmPassword.current.value
    if(matchPassword!=""){
      if(!regularExpression.test(matchPassword)) {
        setPasswordInputRes("notmatched");
      }else{
        setPasswordInputRes(true);
      }
      if(matchConfPassword!=""){
        if(matchPassword===matchConfPassword){
          setConfirmPasswordRes("matched");
        }else{
          setConfirmPasswordRes("notmatched");
        }
      }
    }else{
      setPasswordInputRes(false);
    }
  }



  return (
    <div className="h-screen">
      <div className="body max-w-full h-full flex flex-col ">

        <TopNavBar icon={logout} page="register" />
        <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
          <LeftNavBar page="login" />
          <HumMenu />

          <main className="h-full w-full ">
            <div className="flex justify-center lg:h-full items-center ">
              <div className="mx-auto  px-5 ">
                <div className="max-w-lg">
                  <p className="text-center text-3xl loginSubtitle mb-1 mt-5 lg:mt-0">Welcome to Next Metaverse project!</p>
                  <p className="text-center loginTitle mb-6">REGISTER</p>
                  <form onSubmit={submitRegister}>
                    <div className=" ">
                      <label className="">Username</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="text" name="username" ref={userName} placeholder="Username" />
                    </div>
                    {userNameRes === false && (
                      <ul className="text-red-400">
                        <li>Username is required.</li>
                      </ul>
                    )}
                    <div className=" ">
                      <label className="">First Name</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="text" name="firstname" ref={firstName} placeholder="First Name" />
                    </div>
                    {firstNameRes === false && (
                      <ul className="text-red-400">
                        <li>First Name is required.</li>
                      </ul>
                    )}
                    <div className=" ">
                      <label className="">Last Name</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="text" name="lastname" ref={lastName} placeholder="Last Name" />
                    </div>
                    {lastNameRes === false && (
                      <ul className="text-red-400">
                        <li>Last Name is required.</li>
                      </ul>
                    )}
                    <div className=" ">
                      <label className="">Email address</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="email" name="email" ref={emailInputRef} placeholder="Email" />
                    </div>
                    {emailInputRes === false && (
                      <ul className="text-red-400">
                        <li>Email address is required.</li>
                      </ul>
                    )}
                    <div className=" ">
                      <label className="">Password</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="password" ref={password} placeholder="Password"  onKeyUp={correctPassword}/>
                    </div>
                      <ul className="text-red-400">
                    {passwordInputRes === false && (
                        <li>Password is required.</li>
                      )}
                      {passwordInputRes === "notmatched" && (
                          <small>Passwords must be at least 8 characters with one uppercase letter, one number, and one special character.</small>
                     )}
                      </ul>
                    <div className=" ">
                      <label className="">Confirm password</label>
                      <input className="w-full mt-1 bg-light px-6 py-3 rounded-lg text-black" type="password" name="confirmpassword" ref={confirmPassword} placeholder="Confirm password" onKeyUp={matchConfirmPassword}/>
                    </div>
                    <small>Passwords must be at least 8 characters with one uppercase letter, one number, and one special character.</small>
                      <ul className="text-red-400">
                    {confirmpasswordRes === false && (
                        <li>Confirm password is required.</li>
                    )}
                    {confirmpasswordRes === "notmatched" && (
                        <li>Password is not Matched.</li>
                    )}
                      </ul>
                    {confirmpasswordRes === "matched" && (
                      <ul className="text-green-200">
                        <li>Password is Matched.</li>
                      </ul>
                    )}
                    <button className="hover:opacity-75  w-full uppercase text-center bg-gradient-light mt-6 sm:p-3 p-2 rounded-lg">
                      <strong>REGISTER</strong>
                    </button>
                  </form>
                    {responseAjax === false && (
                    <ul className="text-red-400">
                        <li>Authentication failed.</li>
                    </ul>
                    )}
                    {responseAjax === true && (
                    <ul className="text-green-200">
                        <li>Authentication Successful.</li>
                    </ul>
                    )}
                  <div className="flex mt-3 mb-5 lg:mb-0">
                    <Link to="/auth/forgot-password" className="mr-auto  text-s border-white border-b-2">Forgot password?</Link>
                    <Link to="/auth/login" className="border-white border-b-2 text-s">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </main>

        </div>


      </div>
    </div>
  )
}

export default Register