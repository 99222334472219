import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import history from '../assets/img/icons/icon--history.svg'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
import { useContext, useEffect } from 'react';
function HistoryPage(props) { 
  const authCtx = useContext(AuthContext)
   useEffect(() => {
      
      if(Cookies.get('access_token') === undefined) {
        authCtx.logout();
    }
      },[])
    return (
        <div className="h-full">
    <div className="body max-w-full h-full flex flex-col ">
    <TopNavBar icon={history} page="history"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="history"/>
      <HumMenu/>
<main className="w-full h-full px-4 md:ml-12 pt-8">
<div className="max-w-7xl  mx-auto ">
        <div className={`mt-10 rounded-xl overflow-x-auto bg-blue-300 bg-opacity-75 p-6 shadow-inset-card `}>
          <h1>Trading history</h1>
        <table className="table-spacing w-full">
  <thead>
    <tr>
      <th className="py-6 px-2 shadow-inset-card">EVENT</th>
      <th className="py-6 px-2 shadow-inset-card">PRICE</th>
      <th className="py-6 px-2 shadow-inset-card">PRICE(BNB)</th>
      <th className="py-6 px-2 shadow-inset-card">FROM</th>
      <th className="py-6 px-2 shadow-inset-card">TO</th>
      <th className="py-6 px-2 shadow-inset-card" >DATE</th>
    </tr>
  </thead>
  <tbody className="">
    <tr className="bg-primary">
      <th className="text-lg py-6 px-2">11 Place Adolphe Chérioux, 75015 Paris, France</th>
      <th className="py-6 px-2">
      <div className="flex space-x-4 items-center">   
                <i className="fa-solid fa-hotel"></i>
                <div>
                20
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                </div>
      </th>
      <th className="py-6 px-2">34.23 USDT</th>
      <th className="py-6 px-2">0.7 USDT</th>
      <th className="py-6 px-2">43.43 USDT</th>
      <th className="py-6 px-2"><i className="fa-solid fa-hotel"></i></th>
    </tr>
    <tr className="bg-primary">
   
   
     <th className="text-lg p-3">11 Place Adolphe Chérioux, 75015 Paris, France</th>
     <th className="py-6 px-2">
     <div className="flex space-x-4 items-center">
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                20
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                
                <i className="fa-solid fa-hotel"></i>
                <div>
                0
                </div>
                </div>
     </th>
     <th className="py-6 px-2">34.23 USDT</th>
     <th className="py-6 px-2">0.7 USDT</th>
     <th className="py-6 px-2">43.43 USDT</th>
     <th className="py-6 px-2"><i className="fa-solid fa-hotel"></i></th>
   </tr>
    
  </tbody>
</table>
</div>

        </div>
</main>
      
      
    
</div>
    
  </div>
 
  </div>
    )
}


export default HistoryPage