import { Link } from 'react-router-dom'
function Card(props) {
    return (
        <article className=" ">
            <div class="bg-primary rounded-lg mb-5 p-5">
                  <img className="w-full aspect-square mb-4 rounded-lg" src={props.image5} alt="Map view of area"/>
                  <h5 className="mb-4">Rockefeller Center, NewYork</h5>
                  <ul className="flex flex-wrap">
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.buildingAccent} alt=""/>
                        <p className="break-all "><strong><small>21210000601</small></strong></p>
                     </li>
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.layers} alt=""/>
                        <p className=""><strong><small>24</small></strong></p>
                     </li>
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.arrows} alt=""/>
                        <p className=""><strong><small>125 mtr</small></strong></p>
                     </li>
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.warning} alt=""/>
                        <p className=""><strong><small>1002345</small></strong></p>
                     </li>
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.cart} alt=""/>
                        <p className=""><strong><small>Up for sale</small></strong></p>
                     </li>
                     <li className="flex items-center w-48% mb-4">
                        <img className="w-5 h-5 mr-2" src={props.value} alt=""/>
                        <p className=""><strong><small>8,264 MATIC</small></strong></p>
                     </li>
                  </ul>
                
                  <Link to="/building/1"
                          className="w-full flex items-center justify-center uppercase text-center bg-gradient-light p-2 rounded-lg">
                     <img className="brightness-0 invert mr-2" src={props.warning} alt=""/>
                     <strong><small>Read more</small></strong>
                  </Link>
                  </div>
               </article>
    )
}

export default Card 