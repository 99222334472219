import { useState, useEffect } from "react";

function PhotoModal(props) {
    return (
       
        <div className={`${!props.show ? "opacity-1 visible" : "opacity-0 invisible"} transition-all duration-500 block bg-blue-300 md:bg-black md:bg-opacity-80 h-screen w-full fixed inset-0 flex justify-center items-center z-2000`}>
        <div class="bg-primary p-5 rounded-md h-screen md:h-auto w-full md:max-w-2xl md:relative fixed inset-0 md:top-0 z-2000">
            <div class="px-4 md:py-4">
                <div class="flex items-center mt-10 md:mt-0">
                
           <div class="flex mr-auto items-center space-x-4">
           <i class="text-2xl fas fa-image"></i>
               <h1 class=""> UPLOAD IMAGE</h1>
              
               </div> 
               <div onClick={props.handleClose}  class="cursor-pointer"><i class="text-3xl fa-solid fa-xmark"></i></div>
               </div>
               <div class="bg-blue-300  items-center  rounded-md mt-5 p-5">
                   <div class="border-dashed border-2 border-white rounded-md  p-5">
                <div class="flex flex-col items-center">
                     
                    <i class=" text-2xl fa-solid fa-cloud-arrow-up"></i>
                    
                    <div class="">
                        Drag and drop your image here
                    </div>
                </div>
               </div>
               </div>
        </div>
        </div>
        </div>
    )
}

export default PhotoModal