import mm from "../assets/img/mm.png"
import wc from "../assets/img/wc.png"
import {useState} from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
export default function SelectWalletModal(props) {
    const [open, setOpen] = useState(true)
    const wallet=props?.wallet

    return (
        <main className={`${props.walletOnOff ? "flex" : "hidden"} relative z-50 flex-auto h-auto bg-glass pt-16 pb-6 not-sold-wrap ConnctWalletModal`}>

            <div className="w-5/6 max-w-6xl mx-auto buyNowPopUp flex flex-col items-center relative justify-center xl:px-20 md:px-16 px-8 md:py-8 py-6 mt-1 not-sold">

                <div className="relative bg-primary p-5">
                    <div onClick={props.walletOffHandler}
                         className="text-gradient-light absolute top-0 right-0 text-3xl cursor-pointer mr-3">&#10005;</div>
                    <div className="flex justify-between items-center" style={{padding: "30px"}}>
                        {isBrowser?
                        <div className="xl:flex-row text-center mr-5 " style={{cursor: "pointer"}} onClick={()=>{
                       wallet.connect()
                       props.walletOffHandler()
                        }}>
                            <img src={mm} alt=""
                                 style={{margin: "auto", objectFit: "contain", height: "100px", width: "100px"}}/>
                            <h1 className="xl:order-1 order-1  my-4 " style={{padding: "12px 0px"}}>Meta Mask</h1>
                            <p>Connect to your MetaMask Wallet</p>
                        </div>
                        :null}
                        <div className="xl:flex-row text-center ml-5" style={{cursor: "pointer"}} onClick={()=>{
                            wallet.connect("walletconnect")
                            props.walletOffHandler()
                        }}>
                            <img src={wc} alt=""
                                 style={{margin: "auto", objectFit: "contain", height: "100px", width: "100px"}}/>
                            <h1 className="xl:order-1 order-1  my-4 " style={{padding: "12px 0px"}}>WalletConnect</h1>
                            <p>Scan with WalletConnect to connect</p>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
