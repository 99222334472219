import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
import injectSheet from "react-jss";
 
const StyleSheet = {
  container: {
    border: "0px solid rgba(255, 255, 255, 1)",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
    borderRadius: "4px",
    backgroundColor: "white",
    display: "grid",
    padding: "16px",
    width: "100%",
  },
  circle: {
    height: "56px",
    width: "56px",
    borderRadius: "50%"
  },
  line: {
    // width: "96px",
    height: "25px",
    alignSelf: "center",
    marginLeft: "16px",
    borderRadius: "0px"
  }
};
class ShimmerEffect extends Component {
  constructor(props) {
    super(props);
    
  }
  render() {
    const { classes , type } = this.props;
    return (
       <>
        {type === 'notes' ? ( <div className={classes.container}> <Shimmer>
             <div className={classes.line} />
        </Shimmer> </div>) : 
(<div className={classes.container} style={{minHeight:'650px'}}> <Shimmer>
        <div className={classes.line} />
        <div className={classes.line} />
        <div className={classes.line} />
        <div className={classes.line} />
        <div className={classes.line} />
        <div className={classes.line} />
   </Shimmer> </div>)
        }
        </>
        
      
    );
  }
}
 
export default injectSheet(StyleSheet)(ShimmerEffect);