import { useState, useRef, useContext } from 'react';
import AuthContext from '../store/auth-context';
// import classes from './AuthForm.module.css';

const AuthForm = () => {
  const emailInputRef = useRef()
  const passwordInputRef = useRef()
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };
  const authCtx = useContext(AuthContext)
  // console.log(authCtx)
  const submitHandler = (event) => {
    event.preventDefault()
    const enteredEmail = emailInputRef.current.value
    const enteredPassword = passwordInputRef.current.value
   
    let urlencoded = new URLSearchParams();
urlencoded.append("username", enteredEmail);
urlencoded.append("password", enteredPassword);
    setIsLoading(true)
    let url
    if(isLogin) {
      
      url = '/token'
    } else {
    url = '/token'
      
    }
    fetch(url,
      {
        method: 'POST',
        body: urlencoded,
        redirect: 'follow',
        header: {
          'Content-Type' : 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        // console.log(res)
        setIsLoading(false)
        if(res.ok) {
          res.json().then(data => {
          //  console.log(data)
           authCtx.login(data.access_token)
          })
          

        } else {
          res.json().then(data => {
            //show error
            let errorMessage = 'Authentication failed!'
            // if(data && data.error && data.error.message) {
            //  errorMessage = data.error.message
            // }
           
            throw new Error(errorMessage)
            
          })
        }
      }).then((data) => {
       
        
      })
      .catch((err) => {
        
      })
  }

  return (
    <section >
      <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>
      <form onSubmit={submitHandler}>
        <div >
          <label htmlFor='email'>Your Email</label>
          <input type='text' className='text-black' id='email'  ref={emailInputRef}/>
        </div>
        <div >
          <label htmlFor='password'>Your Password</label>
          <input type='password' className='text-black' id='password'  ref={passwordInputRef} />
        </div>
        <div >
        {!isLoading &&  <button>{isLogin ? 'Login' : 'Create Account'}</button>}
        {isLoading && <p>Sending request....</p>}
          <button
            type='button'
          
            onClick={switchAuthModeHandler}
          >
          {isLogin ? 'Create new account' : 'Login with existing account'}
          </button>
        </div>
      </form>
    </section>
  );
};

export default AuthForm;
