import checkmark from '../../assets/img/icons/icon--checkmark.svg'
import constrain from '../../assets/img/icons/icon--constrain.svg'
import filter from '../../assets/img/icons/filters.svg'
import AuthContext from '../../store/auth-context';
import { useState, useRef, useContext, useEffect } from 'react';


function TopNavBar(props) {
  const wallet=props?.wallet
  const authCtx = useContext(AuthContext)
  
  useEffect(()=>{

    // console.log('wallet',wallet)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + authCtx.access_token);
    myHeaders.append("Content-Type", "application/json");
   
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    // wallet?.status == "connected" ? alert(1) : null
    if(wallet != undefined && wallet.status == 'connected'){
      let accountID = wallet.account
      
      fetch("https://api.nextmetaverse.io/users/me", requestOptions).then(res=>res.json()).then((data)=>{
          authCtx.userId = data.id
      }).then(()=>{
   
      var raw = JSON.stringify({
        "wallet_id": accountID,
      });
     
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + authCtx.access_token);
      myHeaders.append("Content-Type", "application/json");
   
      var requestOptions = {
        method: 'PUT',
        body: raw,
        headers: myHeaders,
      };
   
      fetch("https://api.nextmetaverse.io/users/"+authCtx.userId+"/wallet", requestOptions).then(res=>res.json()).then((data)=>{
          // console.log('response',data)
      })
   
    })
      // alert(accountID)
    }
   },[wallet])

  function toggleToolbox() {
    if (document.getElementById("toolbarPanel").classList.contains('block')) {
      document.getElementById("toolbarPanel").classList.add('hidden')
      
      document.getElementById("toolbarPanel").classList.remove('block')
     
      document.getElementById("legend").style.display = "none";
  

     
    } else {
      document.getElementById("toolbarPanel").classList.add('block')
      document.getElementById("toolbarPanel").classList.remove('hidden')
      
    }
  }
    function openNav() {
        var bodyClass =  document.querySelector('body')
        bodyClass.classList.toggle('closed') 
      }
 return (
<nav class="md:relative fixed z-10 w-full flex items-center bg-primary">
      <button onClick={openNav} id="btn-toggle-menu" class="flex items-center group text-white text-ne-deepPurple nav-button px-1 btn-toggle-menu  mobi-toggle">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
      
      </button>
      <div class="flex w-full items-center">
      <div class="md:flex-1 flex-none justify-start flex items-center">
      <img class="h-5 w-5 mr-2 md:ml-16" src={props.icon} alt="" />
             <h5 class=" text-xxs uppercase">{props.page}</h5> 
             
             </div>
             <div  class="flex  justify-start flex-none lg:flex-1  items-center space-x-1 mx-8">
             <img onClick={toggleToolbox} class="cursor-pointer w-5 h-5" src={filter} alt="Constrain" />
             <p onClick={toggleToolbox} class="cursor-pointer text-xs">Toolbox</p>
             </div>
      <div class="flex-1 justify-end  flex items-center   md:py-03  p-3">
       
        <p class="text-xs mr-3">
         
          {wallet?.status=="connected"?
          <>

          <span class="mr-5 hidden md:inline-block">Wallet Connected</span>
          <span class="sm:inline-block hidden">{wallet?.account?.slice(0,6)+"..." +wallet?.account?.slice(38,42)}</span>
          </>
          :
          <button onClick={props?.walletOnHandler} >Connect Wallet</button>
 }

        </p>
        <img class="mr-3" src={checkmark} alt="Wallet connected" />
        
        <img src={constrain} alt="Constrain" />
      </div>
      </div>
    </nav>
 )
}

export default TopNavBar