
import { useState, useEffect, useContext } from "react";
import LeftNavBar from '../components/layout/LeftNavBar'
import TopNavBar from '../components/layout/TopNavBar'
import HumMenu from '../components/layout/HumMenu'
import leaderboard from '../assets/img/icons/icon--leaderboard.svg'
import AuthContext from "../store/auth-context";
import Cookies from 'js-cookie';
function MapPage(props) {
  const authCtx = useContext(AuthContext)
   useEffect(() => {
      
      if(Cookies.get('access_token') === undefined) {
        authCtx.logout();
    }
      },[])
    return (
        <div className="h-screen">
    <div className="body max-w-full h-full flex flex-col ">
    
    <TopNavBar icon={leaderboard} page="leaderboard"/>
    <div className="flex flex-auto items-stretch md:pt-0 pt-10 h-full">
      <LeftNavBar page="leaderboard"/>
      <HumMenu/>

      <main className="flex flex-auto h-full m:items-center m:justify-center bg-glass py-12">
        <article className="mx-auto table-container">
          <div className="flex md:flex-row flex-col md:items-center md:justify-between bg-gradient-light lg:px-12 sm:px-8 px-5 py-4 rounded-t-lg">
            <h1 className="lg:text-4xl sm:text-3xl text-lg uppercase break-all md:mb-0 mb-2">
              User Leaderboard
            </h1>
            <div className="md:w-48 w-full h-10 bg-dark px-6 rounded-lg">
              <select className="w-full h-10 bg-dark">
                <option>User</option>
                <option>User</option>
                <option>User</option>
              </select>
            </div>
          </div>
          <div className="xl:overflow-visible overflow-scroll rounded-b-lg scrollbar-hidden">
            <table className="w-full text-center bg-gradient-dark">
              <thead>
                <tr className="">
                  <th className="uppercase lg:p-5 sm:p-3 p-2 border-r-2 border-purple-200 bg-gradient-dark ">
                    <h5 className="lg:text-2xl md:text-lg text-xxs break-words">Rank</h5>
                  </th>
                  <th className="uppercase lg:p-5 sm:p-3 p-2 border-r-2 border-purple-200 bg-gradient-dark ">
                    <h5 className="lg:text-2xl md:text-lg text-xxs break-words">Username</h5>
                  </th>
                  <th className="uppercase lg:p-5 sm:p-3 p-2 border-r-2 border-purple-200 bg-gradient-dark ">
                    <h5 className="lg:text-2xl md:text-lg text-xxs break-words flex items-center justify-center">
                      <span>Buildings</span>
                      <svg data-v-1476055e="" data-v-1a35ac0e="" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline w-4 md:mt-0 md:ml-2 opacity-50"><path data-v-1476055e="" data-v-1a35ac0e="" d="M5 5.5L10 0.5L0 0.5L5 5.5Z" fill="white"></path></svg>
                    </h5>
                    
                  </th>
                  <th className="uppercase lg:p-5 sm:p-3 p-2 bg-gradient-dark ">
                    <h5 className="lg:text-2xl md:text-lg text-xxs break-words flex items-center justify-center">
                      <span>Total floors</span>
                      <svg data-v-1476055e="" data-v-1a35ac0e="" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline w-4 md:mt-0 md:ml-2 opacity-50"><path data-v-1476055e="" data-v-1a35ac0e="" d="M5 5.5L10 0.5L0 0.5L5 5.5Z" fill="white"></path></svg>
                    </h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-table-row">
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 px-2 py-3 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 px-2 py-3">6,451</td>
                </tr>
                <tr>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 sm:p-3 p-2">6,451</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 sm:p-3 p-2">6,451</td>
                </tr>
                <tr>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 sm:p-3 p-2">6,451</td>
                </tr>
                <tr className="bg-table-row">
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">1</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">Oracle</td>
                  <td className="md:p-4 sm:p-3 p-2 border-r-2 border-purple-200">272,945</td>
                  <td className="md:p-4 sm:p-3 p-2">6,451</td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </main>
      
    </div>

    
  </div>
  </div>
    )
}

export default MapPage